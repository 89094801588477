import React, { useState, createRef, useEffect } from "react";
import { Stage, Layer, Rect, Line, Text } from "react-konva";
import { roundPercentage } from "../../../utils/format";
import PropTypes from "prop-types";

/**
 * Represents a vertical single bar graph.
 */
const VerticalSingleBarGraph = ({ max, min, current }) => {
  const [graphWidth, setGraphWidth] = useState(0);
  const divRef = createRef();

  const indicatorValue = (current - min) / (max - min);
  const indicatorPosition = 20 + indicatorValue * 85;

  useEffect(() => {
    if (divRef.current) {
      const { current } = divRef;
      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;

      setGraphWidth(width);
    }
  }, [divRef]);

  return (
    <div style={{ width: "100%", marginTop: "1rem" }} ref={divRef}>
      <Stage width={graphWidth} height={100}>
        <Layer>
          {/* line */}
          <Rect
            x={20}
            y={20}
            width={graphWidth - 50}
            height={5}
            fill="#EBEBEA"
          />
          {/* first triangle */}
          <Line
            x={graphWidth - 25}
            y={15}
            points={[-15, 7.5, 0, 0, 0, 15]}
            tension={0}
            closed
            fill="#FF9A89"
          />
          <Text
            x={graphWidth - 40}
            y={30}
            text={roundPercentage(max)}
            fill="#FF9A89"
          />
          {/* second triangle */}
          <Line
            x={10}
            y={15}
            points={[0, 15, 0, 0, 15, 7.5]}
            tension={0}
            closed
            fill="#649BFF"
          />
          <Text x={2} y={30} text={roundPercentage(min)} fill="#649BFF" />
          {/* indicator */}
          <Line
            x={indicatorPosition}
            y={18}
            points={[0, 5, 5, 0, 10, 5, 5, 10]}
            tension={0}
            closed
            fill="black"
          />
          <Text
            x={indicatorPosition - 5}
            y={30}
            text={roundPercentage(current)}
            fill="black"
          />
        </Layer>
      </Stage>
    </div>
  );
};

VerticalSingleBarGraph.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
};

export default VerticalSingleBarGraph;
