import { useHistory } from "react-router-dom";
import { Navigation } from "react-minimal-side-navigation";
import PersonalBanker from "./PersonalBanker";
import Search from "./Search";
import UpcomingEvents from "./UpcomingEvents";

const menu = [
  {
    title: "Portfolio Overview",
    itemId: "/portfolio",
    subNav: [
      {
        title: "Services & Allocation",
        itemId: "/portfolio/services-allocation",
      },
      {
        title: "Portfolio Details",
        itemId: "/portfolio/portfolio-details",
      },
      {
        title: "Performance",
        itemId: "/portfolio/performance",
      },
      {
        title: "Asset allocation evolution",
        itemId: "/portfolio/asset-allocation-evolution",
      },
      {
        title: "Agenda",
        itemId: "/portfolio/agenda",
      },
      {
        title: "Cash flow projection",
        itemId: "/portfolio/cash-flow-projection",
      },
    ],
  },
  {
    title: "Portfolio Analysis",
    itemId: "/portfolio-analysis",
    subNav: [
      {
        title: "Cash",
        itemId: "/portfolio-analysis/cash",
      },
      {
        title: "Fixed Income",
        itemId: "/portfolio-analysis/fixed-income",
      },
      {
        title: "Equities",
        itemId: "/portfolio-analysis/equities",
      },
      {
        title: "Alternative Investments",
        itemId: "/portfolio-analysis/alternative-investments",
      },
      {
        title: "Other Investments",
        itemId: "/portfolio-analysis/other-investments",
      },
      // {
      //   title: "Portfolio Check",
      //   itemId: "/portfolio-analysis/portfolio-check",
      // },
      {
        title: "Performance",
        itemId: "/portfolio-analysis/performance",
      },
    ],
  },
  // {
  //   title: "Investment Tools",
  //   itemId: "/investment-tools",
  // },
];

/**
 * Represents the portfolio overview or portfolio analysis sidebar.
 */
const Sidebar = () => {
  const history = useHistory();

  return (
    <div className="sidebar d-none d-md-block">
      <div className="sticky">
        <section className="bg-blue">
          <h3 className="sidebar-section-title">Consolidated Position</h3>

          <dl className="d-flex mb-2">
            <dt className="flex-grow-1">Risk Profile</dt>
            <dd className="flex-grow-1 text-right m-0">Income</dd>
          </dl>
          <dl className="d-flex mb-2">
            <dt className="flex-grow-1">Net Value</dt>
            <dd className="flex-grow-1 text-right  m-0">3’020’010.23</dd>
          </dl>
          <dl className="d-flex mb-2">
            <dt className="flex-grow-1">Reference Currency</dt>
            <dd className="flex-grow-1 text-right  m-0">USD</dd>
          </dl>
          <dl className="d-flex mb-2">
            <dt className="flex-grow-1">Performance YTD</dt>
            <dd className="flex-grow-1 text-right  m-0">35.50%</dd>
          </dl>

          <hr />

          <Navigation
            activeItemId={window.location.pathname}
            onSelect={({ itemId }) => {
              const current_path = window.location.pathname.split("/");
              const path = itemId.split("/");

              if (current_path.length > 1 && path.length > 1 && current_path[1] != path[1]) {
                history.push(itemId);
              }

              console.log(itemId);

              const items = {
                "/portfolio/services-allocation": "#portfolio-services-allocation",
                "/portfolio/portfolio-details": "#portfolio-portfolio-details",
                "/portfolio/performance": "#portfolio-performance",
                "/portfolio/asset-allocation-evolution": "#portfolio-asset-allocation-evolution",
                "/portfolio/agenda": "#portfolio-overview-agenda",
                "/portfolio/cash-flow-projection": "#portfolio-cash-flow-projection",

                "/portfolio-analysis/cash": "#portfolio-analysis-cash",
                "/portfolio-analysis/fixed-income": "#portfolio-analysis-fixed-income",
                "/portfolio-analysis/equities": "#portfolio-analysis-equities",
                "/portfolio-analysis/alternative-investments": "#portfolio-analysis-alternative-investments",
                "/portfolio-analysis/other-investments": "#portfolio-analysis-other-investments",
                "/portfolio-analysis/portfolio-check": "#portfolio-analysis-portfolio-check",
                "/portfolio-analysis/performance": "#portfolio-analysis-performance",
              };

              if (items[itemId]) {
                const element = document.querySelector(items[itemId]);

                if (element) {
                  element.scrollIntoView({ behavior: "smooth" });
                }
              }
            }}
            items={menu}
          />

          <div className="row no-gutters my-3">
            <div className="col mr-1">
              <a className="d-block btn-round" href="?">
                Trade
              </a>
            </div>
            <div className="col ml-1">
              <a className="d-block btn-round" href="?">
                Transfer
              </a>
            </div>
          </div>

          <PersonalBanker name="Justin Merville" avatar="/images/justin-merville.png" status="online" />
        </section>

        <UpcomingEvents />

        <Search />
      </div>
    </div>
  );
};

export default Sidebar;
