import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import MobileCard from "../../MobileCard";
import MobileSection from "../../MobileSection";
import usePerformance from "./hook";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ReactComponent as CumulativeIcon } from "./CumulativeIcon.svg";
import { ReactComponent as MonthIcon } from "./MonthIcon.svg";
import MobilePagination from "../../MobilePagination";
import { Modal } from "react-bootstrap";
import MobileDatePicker from "../../MobileDatePicker";

/**
 * Mobile version of the performance component of the portfolio overview page.
 */
const MobilePerformance = () => {
  const {
    loading,
    performanceOverPeriod,
    annualizedPerformanceOverPeriod,
    performanceSinceInception,
    annualizedPerformanceSinceInception,
    performanceChartData,
    setStartDate,
    setEndDate,
  } = usePerformance();

  const [showDateModal, setShowDateModal] = useState(false);

  if (loading) return <Spinner animation="border" />;

  return (
    <div style={{ marginTop: "1rem" }}>
      <MobileSection
        title="Performance"
        subtitle="A label lorem lorem lorem lorem, Click the lorem lorem lorem"
      >
        <MobileCard>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <div className="row" style={{ alignItems: "flex-end" }}>
              <div className="col col-8" style={{ color: "#649BFF" }}>
                Performance over period chosen
              </div>
              <div
                className="col col-4"
                style={{ color: "#91989E" }}
              >{`${performanceOverPeriod}%`}</div>
            </div>
            <div className="row" style={{ alignItems: "flex-end" }}>
              <div className="col col-8" style={{ color: "#649BFF" }}>
                Annualized performance over period chosen
              </div>
              <div
                className="col col-4"
                style={{ color: "#91989E" }}
              >{`${annualizedPerformanceOverPeriod}%`}</div>
            </div>
            <div
              className="row"
              style={{ marginTop: "1rem", alignItems: "flex-end" }}
            >
              <div className="col col-8" style={{ color: "#91989E" }}>
                Performance since inception
              </div>
              <div
                className="col col-4"
                style={{ color: "#91989E" }}
              >{`${performanceSinceInception}%`}</div>
            </div>
            <div className="row" style={{ alignItems: "flex-end" }}>
              <div className="col col-8" style={{ color: "#91989E" }}>
                Annualized performance since inception
              </div>
              <div
                className="col col-4"
                style={{ color: "#91989E" }}
              >{`${annualizedPerformanceSinceInception}%`}</div>
            </div>
          </div>

          <ResponsiveContainer width="99%" height={300}>
            <ComposedChart
              height={400}
              data={performanceChartData}
              margin={{ top: 10, bottom: 10 }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="yearMonth" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="monthlyPerformance" barSize={20} fill="#649BFF" />
              <Line
                type="monotone"
                dataKey="cumulativePerformance"
                stroke="#FF9A89"
              />
            </ComposedChart>
          </ResponsiveContainer>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            <div
              style={{
                margin: "0.5rem",
                color: "#91989E",
                display: "flex",
              }}
            >
              <CumulativeIcon
                style={{ marginRight: "0.2rem", height: "14px" }}
              />
              Cumulative
            </div>
            <div
              style={{
                margin: "0.5rem",
                color: "#91989E",
                display: "flex",
              }}
            >
              <MonthIcon style={{ marginRight: "0.2rem", height: "14px" }} />
              Month
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              backgroundColor: "rgba(100, 155, 255, 0.1)",
              padding: "0.5rem",
              marginLeft: "-0.5rem",
              marginRight: "-0.5rem",
              marginBottom: "1rem",
            }}
          >
            <button
              className="btn btn-outline-primary"
              style={{ width: "80%" }}
              onClick={() => setShowDateModal(true)}
            >
              Filters
            </button>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="btn btn-outline-primary"
              style={{ width: "80%" }}
            >
              Show most recent performance
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "0.5rem",
            }}
          >
            <button
              className="btn btn-outline-primary"
              style={{ width: "80%" }}
            >
              Show monthly performance
            </button>
          </div>
        </MobileCard>
      </MobileSection>

      <MobilePagination
        leftLink="/portfolio/portfolio-details"
        leftText="Portfolio Details"
        rightLink="/portfolio/asset-allocation-evolution"
        rightText="Asset allocation evolution"
      />

      <MobileDatePicker
        showDateModal={showDateModal}
        setShowDateModal={setShowDateModal}
        onDateSelect={(startDate, endDate) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
      />
    </div>
  );
};

export default MobilePerformance;
