import React from "react";
import { roundPercentage, roundNumber } from "../../utils/format";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
import PropTypes from "prop-types";
import { colors } from "../../utils/colors";

/**
 * Represents a custom pie chart.
 */
const CustomPieChart = ({
  items,
  amountOrPercentage,
  nameKey,
  layout = "horizontal",
  innerRadius = 50,
  outerRadius = 90,
}) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={items}
          dataKey={amountOrPercentage === "amount" ? "Amount" : "Percentage"}
          nameKey={nameKey}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#82ca9d"
          isAnimationActive={false}
          label={(data) =>
            amountOrPercentage === "amount"
              ? roundNumber(data.Amount).toLocaleString()
              : `${roundPercentage(data.Percentage)}%`
          }
        >
          {items.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip
          formatter={(value) => {
            if (amountOrPercentage === "amount") {
              return roundNumber(value).toLocaleString();
            } else {
              return `${roundPercentage(value)}%`;
            }
          }}
        />
        {layout === "horizontal" ? (
          <Legend iconType="circle" />
        ) : (
          <Legend
            iconType="circle"
            layout="vertical"
            verticalAlign="middle"
            align="left"
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

CustomPieChart.propTypes = {
  items: PropTypes.array.isRequired,
  amountOrPercentage: PropTypes.string.isRequired,
  nameKey: PropTypes.string.isRequired,
  layout: PropTypes.string,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
};

CustomPieChart.defaultProps = {
  layout: "horizontal",
  innerRadius: 50,
  outerRadius: 90,
};

export default CustomPieChart;
