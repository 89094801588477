import React, { Component } from "react";
import Logo from "../assets/images/MillenniumBanquePrivee.png";
import LangSwitch from "./LangSwitch";

//import { AiOutlineUser, AiOutlineSearch } from "react-icons/ai";

import { FiArrowLeft } from "react-icons/fi";

/**
 * Represents a header of the login page.
 */
class AppHeaderLogin extends Component {
  state = {
    langs: ["PT", "EN", "CN", "FR"],
    currentLang: "EN",
  };
  render() {
    return (
      <header className="border-bottom flex-none">
        <div className="header-container">
          <div className="back-home align-items-stretch justify-content-center d-flex">
            <a
              href="?"
              className="d-flex align-items-center justify-content-center flex-grow-1"
            >
              <FiArrowLeft />
              <span>Home</span>
            </a>
          </div>

          <div className="logo">
            <a href="?">
              <img src={Logo} alt="Millennium Banque Privée" />
            </a>
          </div>

          <LangSwitch current="EN"></LangSwitch>
        </div>
      </header>
    );
  }
}

export default AppHeaderLogin;
