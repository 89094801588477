import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * Represents the mobile slide menu.
 */
const MobileSlideMenu = ({ onCloseMenu }) => {
  const history = useHistory();
  const [menu, setMenu] = useState([
    {
      title: "Accounts & Wealth",
      url: "?",
      subNav: [
        {
          title: "Portfolio Overview",
          url: "?",
          subNav: [
            {
              title: "Consolidated Position",
              url: "?",
            },
            {
              title: "Account #98765 Discretionary",
              url: "?",
              subNav: [
                {
                  title: "Services & Allocation",
                  url: "?",
                },
                {
                  title: "Portfolio Details",
                  url: "?",
                },
                {
                  title: "Performance",
                  url: "?",
                },
                {
                  title: "Asset allocation evolution",
                  url: "?",
                },
                {
                  title: "Agenda",
                  url: "?",
                },
                {
                  title: "Cash flow projection",
                  url: "?",
                },
              ],
            },
            {
              title: "Account #56281 Execution",
              url: "?",
            },
            {
              title: "Account #16281 Advisory",
              url: "?",
            },
          ],
        },
        {
          title: "Portfolio Analysis",
          url: "?",
        },
        {
          title: "Investment Tools",
          url: "?",
        },
      ],
    },
    {
      title: "Products & Services",
      url: "/products-services",
    },
    {
      title: "MSafe",
      url: "?",
    },
    {
      title: "MLearning",
      url: "?",
    },
    {
      title: "News & Events",
      url: "?",
    },
    {
      title: "Legal Information and Contacts",
      url: "?",
    },
  ]);

  const setKeys = (items) => {
    items = items.map((item) => {
      item.key = count;
      count++;

      if (item.subNav) {
        item.subNav = setKeys(item.subNav);
      }

      return item;
    });

    return items;
  };

  let count = 10000;

  useEffect(() => {
    setMenu(setKeys(menu));
  }, []);

  const toggleActive = (menuItems, item) => {
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].key === item.key) {
        if (menuItems[i].active) {
          delete menuItems[i].active;
        } else {
          menuItems[i].active = true;
        }
      }
      if (menuItems[i].subNav) {
        menuItems[i].subNav = toggleActive(menuItems[i].subNav, item);
      }
    }

    return menuItems;
  };

  const handleClick = (item) => {
    if (!item.subNav) {
      console.log(item.url);
      history.push(item.url);
      onCloseMenu();
      return;
    }

    let menuCopy = [...menu];
    menuCopy = toggleActive(menuCopy, item);
    setMenu(menuCopy);
  };

  const menuSubNav = (items, parentItem) => {
    if (!items.length) return "";

    return (
      <ul className="subnav">
        <li className="back" key={"back-" + parentItem.key}>
          <a
            href="{parentItem.url}"
            onClick={(e) => {
              e.preventDefault();
              handleClick(parentItem);
            }}
          >
            <strong>{parentItem.title}</strong>
          </a>
        </li>
        {items.map((item) => {
          const cssClasses = [];
          if (item.subNav) cssClasses.push("has-subnav");
          if (item.active) cssClasses.push("active");
          return (
            <li key={item.key} className={cssClasses.join(" ")}>
              <a
                href={item.url}
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(item);
                }}
              >
                {item.title}
              </a>
              {item.subNav && menuSubNav(item.subNav, item)}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <ul className="mobile-slide-menu">
      {menu.map((item) => {
        const cssClasses = [];
        if (item.subNav) cssClasses.push("has-subnav");
        if (item.active) cssClasses.push("active");
        return (
          <li key={item.key} className={cssClasses.join(" ")}>
            <a
              href={item.url}
              onClick={(e) => {
                e.preventDefault();
                handleClick(item);
              }}
            >
              {item.title}
            </a>
            {item.subNav && menuSubNav(item.subNav, item)}
          </li>
        );
      })}
    </ul>
  );
};

export default MobileSlideMenu;
