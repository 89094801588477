import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import MobileCardWithAmountOrPercentageToggle from "../../MobileCardWithAmountOrPercentageToggle";
import MobileSection from "../../MobileSection";
import useOtherInvestments from "./hook";
import CustomPieChart from "../../charts/CustomPieChart";
import MobileCard from "../../MobileCard";
import MobilePagination from "../../MobilePagination";

/**
 * Represents the mobile version of the other investments component.
 */
const MobileOtherInvestments = () => {
  const { currency, accountId, boxText, loading, dataByCurrency } =
    useOtherInvestments();
  const [
    cambialExposureAmountOrPercentage,
    setCambialExposureAmountOrPercentage,
  ] = useState("amount");
  const [
    exposureByCurrencyAmountOrPercentage,
    setExposureByCurrencyAmountOrPercentage,
  ] = useState("amount");

  if (loading)
    return (
      <MobileSection
        title="Other Investments"
        subtitle="label explaining what we are seeing"
      >
        <Spinner animation="border" />
      </MobileSection>
    );

  return (
    <div>
      <MobileSection
        title="Other Investments"
        subtitle="label explaining what we are seeing"
        secondSubtitle={boxText}
      >
        <MobileCardWithAmountOrPercentageToggle
          title="Exposição cambial"
          subtitle="Click a currency to see risk metrics - only for direct investments, excluding funds and ETFs"
          amountOrPercentage={cambialExposureAmountOrPercentage}
          setAmountOrPercentage={setCambialExposureAmountOrPercentage}
        >
          <p className="text-center mt-5">API Method not provided</p>
        </MobileCardWithAmountOrPercentageToggle>

        <MobileCardWithAmountOrPercentageToggle
          title="Exposure by currency"
          amountOrPercentage={exposureByCurrencyAmountOrPercentage}
          setAmountOrPercentage={setExposureByCurrencyAmountOrPercentage}
        >
          <CustomPieChart
            amountOrPercentage={exposureByCurrencyAmountOrPercentage}
            items={dataByCurrency.Items}
            nameKey="CurrencySymbol"
            layout="vertical"
          />
        </MobileCardWithAmountOrPercentageToggle>

        <MobileCard title="Structure of Other Investments instruments"></MobileCard>
      </MobileSection>

      <MobilePagination
        leftText="Alternative Investments"
        leftLink="/portfolio-analysis/alternative-investments"
        rightText="Porfolio Check"
        rightLink="/portfolio-analysis/portfolio-check"
      />
    </div>
  );
};

export default MobileOtherInvestments;
