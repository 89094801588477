import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import "chartjs-plugin-labels";
import { config } from "./config/doughnut";
import { portfolioListAssetsByAssetClass } from "../../api";

const AssetsByAssetClass = ({ token }) => {
  const chartContainer = useRef(null);

  const accountId = "1000025";
  const currency = "EUR";

  useEffect(() => {
    const fetchData = async () => {
      const data = await portfolioListAssetsByAssetClass(accountId, currency);

      const items = data.Items;

      const chartColors = ["#91989E", "#C4C4C4", "#FF9A89"];
      const chartData = [];
      const labels = [];
      const colors = [];

      items.forEach((item, i) => {
        chartData.push(item.Amount);
        labels.push(item.AssetClassName);
        colors.push(chartColors[i]);
      });

      const chartConfig = config(chartData, labels, colors);

      if (chartContainer && chartContainer.current) {
        new Chart(chartContainer.current, chartConfig);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <canvas ref={chartContainer} style={{ maxWidth: 600 }} />
    </div>
  );
};

export default AssetsByAssetClass;
