import moment from "moment";
import _ from "lodash";

/**
 * Round a number.
 */
export const roundNumber = (num) => (num ? Math.round(num * 1000) / 1000 : 0);
/**
 * Round a percentage.
 */
export const roundPercentage = (num) => roundNumber(num * 100);
/**
 *
 * Format a date.
 */
export const formatDate = (date) =>
  date ? moment(date).format("YYYY-MM-DD") : "-";
/**
 * Format money.
 */
export const formatMoney = (value) =>
  value
    ? value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : "-";

/**
 * Transform a amount or a percentage.
 */
export const transformAmountOrPercentage = (amountOrPercentage, data) => {
  let dataTransformed;

  if (amountOrPercentage === "amount") {
    dataTransformed = _.map(data, "Amount");
  } else {
    const dataPercentage = _.map(data, "Percentage");
    dataTransformed = _.map(dataPercentage, (item) => roundPercentage(item));
  }

  return dataTransformed;
};
