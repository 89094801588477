import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Cash from "../components/PortfolioAnalysis/Cash/Desktop";
import FixedIncome from "../components/PortfolioAnalysis/FixedIncome/Desktop";
import Equities from "../components/PortfolioAnalysis/Equities/Desktop";
import AlternativeInvestments from "../components/PortfolioAnalysis/AlternativeInvestments/Desktop";
import OtherInvestments from "../components/PortfolioAnalysis/OtherInvestments/Desktop";
import PortfolioCheck from "../components/PortfolioAnalysis/PortfolioCheck/Desktop";
import Performance from "../components/PortfolioAnalysis/Performance";
import Accounts from "../components/Accounts/Desktop";
import { Spinner } from "react-bootstrap";
import { testSecret } from "../api";
import useIsMobile from "../hooks/isMobileHook";
import CashMobile from "../components/PortfolioAnalysis/Cash/Mobile";
import { Route } from "react-router-dom";
import MobileFixedIncome from "../components/PortfolioAnalysis/FixedIncome/Mobile";
import MobileEquities from "../components/PortfolioAnalysis/Equities/Mobile";
import MobileAlternativeInvestments from "../components/PortfolioAnalysis/AlternativeInvestments/Mobile";
import MobileOtherInvestments from "../components/PortfolioAnalysis/OtherInvestments/Mobile";
import MobilePortfolioCheck from "../components/PortfolioAnalysis/PortfolioCheck/Mobile";
import AccountsMobile from "../components/Accounts/Mobile";
import MobileNavigation from "../components/MobileNavigation";

/**
 * Represents the Portfolio Analysis page.
 */
const PortfolioAnalysis = () => {
  const [loading, setLoading] = useState(true);
  const isMobile = useIsMobile();

  useEffect(() => {
    testSecret()
      .then()
      .catch(() => {
        window.location.href = "/";
      })
      .finally(() => {
        setLoading(false);
      });

    // const script = document.createElement("script");
    // script.src = "https://mbpv.acp.unblu-env.com/unblu/visitor.js?x-unblu-apikey=FAxvpmCgRIiy1Sv8sk9vSw";
    // script.async = true;
    // document.body.appendChild(script);

    return () => {
      // document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <div className="flex-fill content-wrapper">
          {loading && <Spinner animation="border" />}

          {!loading && !isMobile && (
            <>
              <div className="row">
                <div className="col col-3" style={{ marginTop: "-1.5rem" }}>
                  <Sidebar />
                </div>
                <div className="col col-9">
                  <Accounts />

                  <Cash />

                  <FixedIncome />

                  <Equities />

                  <AlternativeInvestments />

                  <OtherInvestments />

                  {false && <PortfolioCheck />}

                  <Performance />
                </div>
              </div>
            </>
          )}

          {!loading && isMobile && (
            <div style={{ padding: "0.5rem" }}>
              <div className="row">
                <div className="col col-10">
                  <AccountsMobile />
                </div>
                <div className="col col-2">
                  <MobileNavigation
                    links={[
                      { name: "Cash", url: "/portfolio-analysis" },
                      {
                        name: "Fixed income",
                        url: "/portfolio-analysis/fixed-income",
                      },
                      { name: "Equities", url: "/portfolio-analysis/equities" },
                      {
                        name: "Alternative investments",
                        url: "/portfolio-analysis/alternative-investments",
                      },
                      {
                        name: "Other investments",
                        url: "/portfolio-analysis/other-investments",
                      },
                      {
                        name: "Portfolio check",
                        url: "/portfolio-analysis/portfolio-check",
                      },
                    ]}
                  />
                </div>
              </div>

              <Route exact path="/portfolio-analysis">
                <CashMobile />
              </Route>
              <Route path="/portfolio-analysis/fixed-income">
                <MobileFixedIncome />
              </Route>
              <Route path="/portfolio-analysis/equities">
                <MobileEquities />
              </Route>
              <Route path="/portfolio-analysis/alternative-investments">
                <MobileAlternativeInvestments />
              </Route>
              <Route path="/portfolio-analysis/other-investments">
                <MobileOtherInvestments />
              </Route>
              <Route path="/portfolio-analysis/portfolio-check">
                <MobilePortfolioCheck />
              </Route>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioAnalysis;
