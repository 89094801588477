import { useEffect, useState } from "react";
import {
  portfolioGetTotalAssetClass,
  portfolioListPortfolioByCurrency,
} from "../../../api";
import { useAccount } from "../../../AccountContext";
import { formatMoney } from "../../../utils/format";

/**
 * This hook has the logic of other investments component.
 */
const useOtherInvestments = () => {
  const currency = "EUR";
  const accountId = useAccount();
  const [boxText, setBoxText] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataByCurrency, setDataByCurrency] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const totalAssetClassData = await portfolioGetTotalAssetClass(
        accountId,
        currency,
        "1"
      );

      const formattedVal = formatMoney(parseFloat(totalAssetClassData));
      setBoxText(`TOTAL OTHER INVESTMENTS: ${formattedVal} ${currency}`);

      // Exposure by currency
      const portfolioByCurrencyData = await portfolioListPortfolioByCurrency(
        accountId,
        currency,
        "1"
      );

      setDataByCurrency(portfolioByCurrencyData);

      setLoading(false);
    };

    fetchData();
  }, [accountId, currency]);

  return { currency, accountId, boxText, loading, dataByCurrency };
};

export default useOtherInvestments;
