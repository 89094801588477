import React, { useState, useEffect } from "react";
import { useAccount } from "../../../AccountContext";
import { formatMoney } from "../../../utils/format";
import _ from "lodash";
import {
  portfolioGetTotalAssetClass,
  portfolioListPortfolioByCurrency,
  portfolioListPortfolioByGeography,
  portfolioListPortfolioByProductType,
  portfolioListPortfolioLines,
} from "../../../api";

/**
 * Has the logic of the equities component.
 */
const useEquities = () => {
  const currency = "EUR";
  const assetClass = "6";
  const accountId = useAccount();
  const [boxText, setBoxText] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataByGeography, setDataByGeography] = useState(null);
  const [dataPortfolioLines, setDataPortfolioLines] = useState(null);
  const [dataByCurrency, setDataByCurrency] = useState(null);
  const [dataByProductType, setDataByProductType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const totalAssetClassData = await portfolioGetTotalAssetClass(
        accountId,
        currency,
        assetClass
      );
      const formattedVal = formatMoney(parseFloat(totalAssetClassData));
      setBoxText(`TOTAL EQUITIES: ${formattedVal} ${currency}`);

      // 5 Maiores Posições
      const portfolioLinesData = await portfolioListPortfolioLines(
        accountId,
        currency,
        assetClass
      );

      setDataPortfolioLines(portfolioLinesData);

      // Alocação por geografia (%)
      const portfolioByGeographyData = await portfolioListPortfolioByGeography(
        accountId,
        currency,
        assetClass
      );

      setDataByGeography(portfolioByGeographyData);

      // Exposição cambial
      const portfolioByCurrencyData = await portfolioListPortfolioByCurrency(
        accountId,
        currency,
        assetClass
      );

      setDataByCurrency(portfolioByCurrencyData);

      // Structure by type of product
      const portfolioByProductTypeData =
        await portfolioListPortfolioByProductType(
          accountId,
          currency,
          assetClass
        );

      setDataByProductType(portfolioByProductTypeData);

      setLoading(false);
    };

    fetchData();
  }, [accountId, currency]);

  return {
    boxText,
    loading,
    dataByGeography,
    dataPortfolioLines,
    dataByCurrency,
    dataByProductType,
  };
};

export default useEquities;
