import React, { useState } from "react";
import { BiLeftArrowCircle, BiRightArrowCircle } from "react-icons/bi";
import PropTypes from "prop-types";

/**
 * Represents the mobile table component.
 */
const MobileTable = ({ header, tableHeaders, rows }) => {
  const [selectedRowInformation, setSelectedRowInformation] = useState(0);

  return (
    <div className="table-responsive">
      <table className="table table-sm ">
        <thead>
          <tr>
            <th
              style={{
                color: "#649BFF",
                border: "0",
              }}
            >
              {header}
            </th>
            <th
              style={{
                border: "0",
                backgroundColor: "#EFF5FF",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  className="btn btn-outline"
                  onClick={() =>
                    setSelectedRowInformation(
                      selectedRowInformation === 0
                        ? tableHeaders.length - 1
                        : selectedRowInformation - 1
                    )
                  }
                >
                  <BiLeftArrowCircle style={{ color: "#649BFF" }} />
                </button>
                <div style={{ color: "#649BFF" }}>
                  {tableHeaders[selectedRowInformation].name}
                </div>
                <button
                  className="btn btn-outline"
                  onClick={() =>
                    setSelectedRowInformation(
                      (selectedRowInformation + 1) % tableHeaders.length
                    )
                  }
                >
                  <BiRightArrowCircle style={{ color: "#649BFF" }} />
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={index}
              style={
                index % 2 ? { backgroundColor: "rgba(100, 156, 255, 0.2)" } : {}
              }
            >
              <td style={{ color: "#91989E", fontSize: "14px" }}>
                {row.ProductName}
              </td>
              <td
                style={{
                  color: "#91989E",
                  fontSize: "14px",
                  backgroundColor: "rgba(239, 245, 255, 0.5)",
                }}
                className="text-center"
              >
                {row[tableHeaders[selectedRowInformation].value]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

MobileTable.propTypes = {
  header: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  tableHeaders: PropTypes.array.isRequired,
};

export default MobileTable;
