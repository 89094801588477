import { NavLink } from "react-router-dom";
import Logo from "../assets/images/MillenniumBanquePrivee.png";
import Notifications from "./Notifications";
import UserCard from "./UserCard";

/**
 * Represents the application header.
 */
const AppHeader = () => {
  return (
    <header className="header-app border-bottom flex-none">
      <div className="header-container-app">
        <div className="d-none d-md-flex">
          <div className="logo">
            <a href="?">
              <img src={Logo} alt="Millennium Banque Privée" />
            </a>
          </div>
          <ul className="main-menu d-flex">
            <li>
              <NavLink to="/dashboard">Accounts & Wealth</NavLink>
            </li>
            <li>
              <NavLink to="/products-services">Products & Services</NavLink>
            </li>
            <li>
              <NavLink to="/msafe">MSafe</NavLink>
            </li>
            <li>
              <NavLink to="/mlearning">MLearning</NavLink>
            </li>
          </ul>
        </div>

        <div className="title-mobile d-flex d-md-none align-items-center">
          <span>Accounts & Wealth</span>
        </div>

        <div className="d-flex">
          <UserCard name="Luis" />

          <div className="message">
            <a href="?">
              <svg
                width="46"
                height="33"
                viewBox="0 0 46 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M41.957 0.229492H4.04297C1.81664 0.229492 0 1.92411 0 4.00464V29.1723C0 31.2461 1.80855 32.9474 4.04297 32.9474H41.957C44.178 32.9474 46 31.2586 46 29.1723V4.00464C46 1.93082 44.1914 0.229492 41.957 0.229492ZM41.3991 2.74625L23.0854 19.846L4.61438 2.74625H41.3991ZM2.69531 28.6513V4.51386L15.6759 16.5306L2.69531 28.6513ZM4.6009 30.4306L17.5896 18.3024L22.1411 22.5154C22.6676 23.0028 23.5184 23.002 24.0431 22.5112L28.4805 18.3678L41.3991 30.4306H4.6009ZM43.3047 28.6513L30.3861 16.5884L43.3047 4.5256V28.6513Z"
                  fill="#649BFF"
                />
              </svg>
            </a>
          </div>

          <Notifications total="3" />
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
