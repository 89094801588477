import React, { useState, useEffect } from "react";
import {
  portfolioListAssetsByCurrency,
  portfolioListAssetsByManagementTypeCategory,
} from "../../../api";
import { useAccount } from "../../../AccountContext";

/**
 * Has the logic of the services allocation component.
 */
const useServicesAllocation = () => {
  const accountId = useAccount();
  const currency = "EUR";

  const [dataServices, setDataServices] = useState(null);
  const [dataCurrency, setDataCurrency] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const assetsByManagementTypeCategory =
          await portfolioListAssetsByManagementTypeCategory(
            accountId,
            currency
          );

        setDataServices(assetsByManagementTypeCategory);

        const assetsByCurrency = await portfolioListAssetsByCurrency(
          accountId,
          currency
        );

        setDataCurrency(assetsByCurrency);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [accountId]);

  return {
    accountId,
    currency,
    dataServices,
    dataCurrency,
    loading,
  };
};

export default useServicesAllocation;
