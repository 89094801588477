import React, { useState } from "react";
import BoxSection from "../../BoxSection";
import { Spinner } from "react-bootstrap";
import AmountOrPercentageToggle from "../../AmountOrPercentageToggle";
import CustomBarChart from "../../charts/CustomBarChart";
import CustomPieChart from "../../charts/CustomPieChart";
import BoxGrey from "../../BoxGrey";
import useEquities from "./hook";

/**
 * Represents the desktop version of the equities component of the portfolio analysis page.
 */
const Equities = () => {
  const [amountOrPercentage, setAmountOrPercentage] = useState("amount");
  const { boxText, loading, dataByGeography, dataPortfolioLines, dataByCurrency, dataByProductType } = useEquities();

  return (
    <BoxSection title="Equities" text={boxText} id="portfolio-analysis-equities">
      {loading && <Spinner animation="border" />}
      {!loading && (
        <div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <p className="section-lead">
                A label lorem lorem lorem lorem lorem,
                <br />
                Click the lorem lorem lorem lorem.
              </p>
            </div>
            <div className="col-sm-12 col-md-6">
              <AmountOrPercentageToggle
                handleChange={(value) => {
                  setAmountOrPercentage(value);
                }}
                amountOrPercentage={amountOrPercentage}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-sm-12 col-md-6">
              <BoxGrey title="Alocação por geografia (%)">
                <CustomBarChart items={dataByGeography.Items} amountOrPercentage={amountOrPercentage} xKey="CountryName" />
              </BoxGrey>
            </div>
            <div className="col-sm-12 col-md-6">
              <BoxGrey title="Exposição cambial">
                <CustomPieChart amountOrPercentage={amountOrPercentage} items={dataByCurrency.Items} nameKey="CurrencySymbol" layout="vertical" />
              </BoxGrey>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <BoxGrey title="5 maiores posições">
                <CustomBarChart items={dataPortfolioLines.Items} amountOrPercentage={amountOrPercentage} xKey="Name" />
              </BoxGrey>
            </div>

            <div className="col-sm-12 col-md-6">
              <BoxGrey title="Structure by type of product">
                <CustomBarChart items={dataByProductType.Items} amountOrPercentage={amountOrPercentage} xKey="ProductType" />
              </BoxGrey>
            </div>
          </div>
        </div>
      )}
    </BoxSection>
  );
};

export default Equities;
