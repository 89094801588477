import React from "react";
import MobileCard from "../../MobileCard";
import MobileSection from "../../MobileSection";
import useCashFlowProjection from "./hook";
import { Spinner } from "react-bootstrap";
import { roundNumber, formatDate } from "../../../utils/format";
import MobilePagination from "../../MobilePagination";

/**
 * Mobile version of the cash flow projection component of the portfolio overview page.
 */
const CashFlowProjectionMobile = () => {
  const { loading, data } = useCashFlowProjection();

  return (
    <div style={{ marginTop: "1rem" }}>
      <MobileSection
        title="Cash Flow Projection"
        subtitle="Values are presented in portfolio reference currency. At the Group level, values are presented in Group reference currency"
      >
        <MobileCard>
          {loading && <Spinner animation="border" />}
          {!loading && (
            <div>
              {!data.length && <p className="empty-result">Nothing to show</p>}

              {data.length > 0 && (
                <>
                  <div className="table-responsive">
                    <table className="table table-sm table-mbp">
                      <thead>
                        <tr>
                          <th>AssetClassId</th>
                          <th>CouponFrequencyId</th>
                          <th>CurrencySymbolId</th>
                          <th>FxRate</th>
                          <th>Interest</th>
                          <th>MaturityDate</th>
                          <th>Name</th>
                          <th>PositionType</th>
                          <th>Quantity</th>
                          <th>Rate</th>
                          <th>RootId</th>
                          <th>SecurityId</th>
                          <th>SecuritySuffix</th>
                          <th>Type</th>
                          <th>Valuation</th>
                          <th>YearlyInterest</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row, index) => (
                          <tr
                            key={index}
                            className={index % 2 ? "even" : "odd"}
                          >
                            <td>{row.AssetClassId}</td>
                            <td>{row.CouponFrequencyId}</td>
                            <td>{row.CurrencySymbolId}</td>
                            <td>{roundNumber(row.FxRate)}</td>
                            <td>{row.Interest}</td>
                            <td>{formatDate(row.MaturityDate)}</td>
                            <td>{row.Name}</td>
                            <td>{row.PositionType}</td>
                            <td>{row.Quantity}</td>
                            <td>{row.Rate}</td>
                            <td>{row.RootId}</td>
                            <td>{row.SecurityId}</td>
                            <td>{row.SecuritySuffix}</td>
                            <td>{row.Type}</td>
                            <td>{row.Valuation}</td>
                            <td>{row.YearlyInterest}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          )}
        </MobileCard>
      </MobileSection>

      <MobilePagination leftLink="/portfolio/agenda" leftText="Agenda" />
    </div>
  );
};

export default CashFlowProjectionMobile;
