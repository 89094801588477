import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { msafeList } from "../../api";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { Dropdown } from "react-bootstrap";

/**
 * Represents the MSafe items list.
 */
const List = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const list = await msafeList();

      console.log(list);

      setItems(list.Items);

      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) return <Spinner animation="border" />;
  return (
    <div
      style={{
        marginTop: "1rem",
        border: "0.382447px solid #EBEBEA",
        boxShadow: "0px 1.52979px 5.7367px rgba(0, 0, 0, 0.2)",
        padding: "1rem",
      }}
    >
      <h4 style={{ color: "#9399A0", fontSize: "20px" }}>
        Files ({items.length})
      </h4>

      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ fontWeight: "bold", color: "#979DA3" }}>Filter</div>
        <div style={{ color: "#D3D3D3", marginLeft: "1rem" }}>By Type</div>
        <div style={{ color: "#D3D3D3", marginLeft: "1rem" }}>
          <Dropdown>
            <Dropdown.Toggle
              style={{
                borderRadius: "30px",
                borderColor: "#D3D3D3",
                backgroundColor: "white",
                color: "#D3D3D3",
              }}
            >
              All
            </Dropdown.Toggle>
          </Dropdown>
        </div>
        <div style={{ color: "#D3D3D3", marginLeft: "1rem" }}>By Date</div>
        <div style={{ color: "#D3D3D3", marginLeft: "1rem" }}>
          <Dropdown>
            <Dropdown.Toggle
              style={{
                borderRadius: "30px",
                borderColor: "#D3D3D3",
                backgroundColor: "white",
                color: "#D3D3D3",
              }}
            >
              Most recent
            </Dropdown.Toggle>
          </Dropdown>
        </div>
        <div style={{ color: "#D3D3D3", marginLeft: "1rem" }}>From</div>
        <div style={{ color: "#D3D3D3", marginLeft: "1rem" }}>
          <Dropdown>
            <Dropdown.Toggle
              style={{
                borderRadius: "30px",
                borderColor: "#D3D3D3",
                backgroundColor: "white",
                color: "#D3D3D3",
              }}
            >
              Date picker
            </Dropdown.Toggle>
          </Dropdown>
        </div>
        <div style={{ color: "#D3D3D3", marginLeft: "1rem" }}>To</div>
        <div style={{ color: "#D3D3D3", marginLeft: "1rem" }}>
          <Dropdown>
            <Dropdown.Toggle
              style={{
                borderRadius: "30px",
                borderColor: "#D3D3D3",
                backgroundColor: "white",
                color: "#D3D3D3",
              }}
            >
              Date picker
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </div>

      <div className="table-responsive">
        <table
          className="table"
          style={{ borderSpacing: "0rem 0.5rem", borderCollapse: "separate" }}
        >
          <thead>
            <tr>
              <th style={{ border: "none", color: "#7CABFE" }}>Viewed</th>
              <th style={{ border: "none", color: "#7CABFE" }}>Type</th>
              <th style={{ border: "none", color: "#7CABFE" }}>Name</th>
              <th style={{ border: "none", color: "#7CABFE" }}>Date</th>
              <th style={{ border: "none", color: "#7CABFE" }}>Category</th>
              <th style={{ border: "none", color: "#7CABFE" }}>Signed</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index} style={{ backgroundColor: "#F9F9F9" }}>
                <td style={{ border: "none" }}>
                  {item.IsViewed ? (
                    <AiFillCheckCircle />
                  ) : (
                    <AiFillCloseCircle />
                  )}
                </td>
                <td style={{ border: "none" }}>{item.ContentType}</td>
                <td style={{ border: "none" }}>{item.Name}</td>
                <td style={{ border: "none" }}>
                  {new Date(item.Date).toLocaleString()}
                </td>
                <td style={{ border: "none" }}>{item.Category}</td>
                <td style={{ border: "none" }}>
                  {item.IsSigned ? (
                    <AiFillCheckCircle />
                  ) : (
                    <AiFillCloseCircle />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default List;
