import React, { useState, useEffect } from "react";
import { useAccount } from "../../../AccountContext";
import { formatMoney } from "../../../utils/format";
import {
  portfolioGetTotalAssetClass,
  portfolioListPortfolioByCreditRating,
  portfolioListPortfolioByCreditRisk,
  portfolioListPortfolioByCurrency,
  portfolioListPortfolioByGeography,
  portfolioListPortfolioByProductType,
} from "../../../api";

/**
 * Has the logic of the fixed income component.
 */
const useFixedIncome = () => {
  const currency = "EUR";
  const assetClass = "4";
  const accountId = useAccount();
  const [boxText, setBoxText] = useState(false);
  const [dataCreditRisk, setDataCreditRisk] = useState(null);
  const [dataByGeography, setDataByGeography] = useState(null);
  const [dataByCurrency, setDataByCurrency] = useState(null);
  const [dataByCreditRating, setDataByCreditRating] = useState(null);
  const [dataByProductType, setDataByProductType] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const totalAssetClassData = await portfolioGetTotalAssetClass(
        accountId,
        currency,
        assetClass
      );

      const formattedVal = formatMoney(parseFloat(totalAssetClassData));
      setBoxText(`TOTAL FIXED INCOME: ${formattedVal} ${currency}`);

      // 1. Alocação por risco de crédito
      const portfolioByCreditRiskData =
        await portfolioListPortfolioByCreditRisk(
          accountId,
          currency,
          assetClass
        );

      setDataCreditRisk(portfolioByCreditRiskData);

      // 2. Alocação por geografia (%)
      const portfolioByGeographyData = await portfolioListPortfolioByGeography(
        accountId,
        currency,
        assetClass
      );

      setDataByGeography(portfolioByGeographyData);

      // 3. Alocação por rating de crédito (API Method not provided)
      const portfolioByCreditRatingData =
        await portfolioListPortfolioByCreditRating(
          accountId,
          currency,
          assetClass
        );

      setDataByCreditRating(portfolioByCreditRatingData);

      // 4. Structure by type of product
      const portfolioByProductTypeData =
        await portfolioListPortfolioByProductType(
          accountId,
          currency,
          assetClass
        );

      setDataByProductType(portfolioByProductTypeData);

      // 5. Structure by currency
      const portfolioByCurrencyData = await portfolioListPortfolioByCurrency(
        accountId,
        currency,
        assetClass
      );

      setDataByCurrency(portfolioByCurrencyData);

      setLoading(false);
    };

    fetchData();
  }, [accountId, currency]);

  return {
    boxText,
    loading,
    dataCreditRisk,
    dataByGeography,
    dataByCurrency,
    dataByCreditRating,
    dataByProductType,
  };
};

export default useFixedIncome;
