import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import MobileCardWithAmountOrPercentageToggle from "../../MobileCardWithAmountOrPercentageToggle";
import MobileSection from "../../MobileSection";
import useAlternativeInvestments from "./hooks";
import CustomPieChart from "../../charts/CustomPieChart";
import MobileCard from "../../MobileCard";
import CustomBarChart from "../../charts/CustomBarChart";
import MobilePagination from "../../MobilePagination";

/**
 * Represents the mobile version of the alternative investments component of the portfolio analysis page.
 */
const MobileAlternativeInvestments = () => {
  const {
    boxText,
    loading,
    dataPortfolioLines,
    dataByCurrency,
    dataByMarketExposure,
  } = useAlternativeInvestments();
  const [
    strategyAllocationAmountOrPercentage,
    setStrategyAllocationAmountOrPercentage,
  ] = useState("amount");
  const [
    cambialExposureAmountOrPercentage,
    setCambialExposureAmountOrPercentage,
  ] = useState("amount");

  if (loading)
    return (
      <MobileSection
        title="Alternative Investments"
        subtitle="label explaining what we are seeing"
      >
        <Spinner animation="border" />
      </MobileSection>
    );

  return (
    <div>
      <MobileSection
        title="Alternative Investments"
        subtitle="label explaining what we are seeing"
        secondSubtitle={boxText}
      >
        <MobileCardWithAmountOrPercentageToggle
          title="Alocação por estratégia"
          amountOrPercentage={strategyAllocationAmountOrPercentage}
          setAmountOrPercentage={setStrategyAllocationAmountOrPercentage}
        >
          <CustomPieChart
            amountOrPercentage={strategyAllocationAmountOrPercentage}
            items={dataByMarketExposure.Items}
            nameKey="MarketExposure"
          />
        </MobileCardWithAmountOrPercentageToggle>

        <MobileCardWithAmountOrPercentageToggle
          title="Exposição cambial"
          subtitle="Click a currency to see risk metrics - only for direct investments, excluding funds and ETFs"
          amountOrPercentage={cambialExposureAmountOrPercentage}
          setAmountOrPercentage={setCambialExposureAmountOrPercentage}
        >
          <CustomPieChart
            amountOrPercentage={cambialExposureAmountOrPercentage}
            items={dataByCurrency.Items}
            nameKey="CurrencySymbol"
            innerRadius={0}
            outerRadius={60}
          />
        </MobileCardWithAmountOrPercentageToggle>

        <MobileCard title="5 maiores posições">
          <CustomBarChart
            items={dataPortfolioLines.Items}
            amountOrPercentage="percentage"
            xKey="Name"
          />
        </MobileCard>
      </MobileSection>

      <MobilePagination
        leftText="Equities"
        leftLink="/portfolio-analysis/equities"
        rightText="Other Investments"
        rightLink="/portfolio-analysis/other-investments"
      />
    </div>
  );
};

export default MobileAlternativeInvestments;
