import React, { useEffect } from "react";

import LoginForm from "../components/LoginForm";

/**
 * Represents the login page.
 */
const Login = () => {
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://mbpv.acp.unblu-env.com/unblu/visitor.js?x-unblu-apikey=NhK9Bf6RSASDtWFblWYQdA";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <div className="flex-fill d-flex">
      <div className="d-md-flex bg-white-blue flex-fill">
        <div className="container d-flex">
          <div className="row">
            <div className="col d-flex flex-column justify-content-center ">
              <LoginForm />
            </div>

            <div className="login-right-col">
              <div className="login-right ">
                <h2 className="title">
                  Important <br />
                  Information
                </h2>

                <p>
                  <em>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum erat diam, consectetur consequat fermentum quis, porttitor a lorem. Etiam a convallis sapien. Mauris consectetur
                    elit risus, ut molestie lorem lobortis sed. Quisque non tempus nisl. In vitae maximus metus, at faucibus arcu. Mauris fermentum imperdiet luctus.
                  </em>
                </p>

                <p>
                  <strong>
                    Curabitur eleifend, ligula eget dignissim luctus, augue urna efficitur arcu, eu venenatis nisi turpis id quam. Aliquam bibendum placerat turpis ut pellentesque. Sed accumsan
                    lobortis augue vel molestie.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
