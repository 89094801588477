import React from "react";
import BoxSection from "../../BoxSection";
import Debug from "../../Debug";
import { Spinner } from "react-bootstrap";
import CustomAreaChart from "../../charts/CustomAreaChart";
import CustomDatePicker from "../../CustomDatePicker";
import useAssetAllocationEvolution from "./hook";

const formatMoney = (value) => {
  if (!value) {
    return "-";
  }

  value = Math.round(parseFloat(value) * 100) / 100;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Desktop version of the asset allocation evolution component of the portfolio overview page.
 */
const AssetAllocationEvolution = () => {
  const {
    loading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dataByMonth,
    accountId,
    currency,
    dataPortfolioByMonth,
  } = useAssetAllocationEvolution();

  return (
    <BoxSection
      title="Asset Allocation Evolution"
      id="portfolio-asset-allocation-evolution"
    >
      {loading && <Spinner animation="border" />}
      {!loading && (
        <div>
          <p className="section-lead">
            A label describing what we are seeing, if you move the line, you
            will be able to see the evolution of the portfolio throughout time
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{ margin: "1rem", color: "#91989E", fontWeight: "bold" }}
            >
              Filter Date
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ color: "#C4C4C4" }}>From</div>
              <CustomDatePicker
                date={startDate}
                setDate={(date) => {
                  setStartDate(date);
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ color: "#C4C4C4" }}>To</div>
              <CustomDatePicker
                date={endDate}
                setDate={(date) => {
                  setEndDate(date);
                }}
              />
            </div>
          </div>

          {dataByMonth && dataByMonth.length > 0 && (
            <div className="table-responsive">
              <table className="table table-sm table-mbp">
                <thead>
                  <tr>
                    <th></th>
                    <th>January</th>
                    <th>February</th>
                    <th>March</th>
                    <th>April</th>
                    <th>May</th>
                    <th>June</th>
                    <th>July</th>
                    <th>August</th>
                    <th>September</th>
                    <th>October</th>
                    <th>November</th>
                    <th>December</th>
                  </tr>
                </thead>
                <tbody>
                  {dataByMonth.map((row) => (
                    <tr key={row["Year"]}>
                      <td>{row["Year"]}</td>
                      <td className="money">{formatMoney(row["01"])}</td>
                      <td className="money">{formatMoney(row["02"])}</td>
                      <td className="money">{formatMoney(row["03"])}</td>
                      <td className="money">{formatMoney(row["04"])}</td>
                      <td className="money">{formatMoney(row["05"])}</td>
                      <td className="money">{formatMoney(row["06"])}</td>
                      <td className="money">{formatMoney(row["07"])}</td>
                      <td className="money">{formatMoney(row["08"])}</td>
                      <td className="money">{formatMoney(row["09"])}</td>
                      <td className="money">{formatMoney(row["10"])}</td>
                      <td className="money">{formatMoney(row["11"])}</td>
                      <td className="money">{formatMoney(row["12"])}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <Debug
            apis={[
              `portfolio/ListPortfolioByMonth/${accountId}/${currency}/${startDate}/${endDate}`,
            ]}
          ></Debug>

          {dataPortfolioByMonth && dataPortfolioByMonth.length > 0 && (
            <>
              <CustomAreaChart items={dataPortfolioByMonth} />

              <div className="mt-4 table-responsive">
                <table className="table table-sm table-mbp">
                  <thead>
                    <tr>
                      <th className="money"></th>
                      <th className="money">Cash</th>
                      <th className="money">Bonds</th>
                      <th className="money">Equities</th>
                      <th className="money">
                        Alternative
                        <br />
                        Investment
                      </th>
                      <th className="money">Derivatives</th>
                      <th className="money">
                        Other
                        <br />
                        Investment
                      </th>
                      <th className="money">
                        Assets
                        <br />
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataPortfolioByMonth.map((row, n) => (
                      <tr key={n}>
                        <td>{row.YearMonth}</td>
                        <td className="money">{formatMoney(row.Cash)}</td>
                        <td className="money">{formatMoney(row.Bonds)}</td>
                        <td className="money">{formatMoney(row.Equities)}</td>
                        <td className="money">
                          {formatMoney(row.Alternatives)}
                        </td>
                        <td className="money">
                          {formatMoney(row.Derivatives)}
                        </td>
                        <td className="money">{formatMoney(row.Other)}</td>
                        <td className="money">-</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          <Debug
            apis={[
              `portfolio/ListPortfolioByAssetClassByMonth/${accountId}/${currency}/${startDate}/${endDate}`,
            ]}
          ></Debug>
        </div>
      )}
    </BoxSection>
  );
};

export default AssetAllocationEvolution;
