import React from "react";
import "./styles.scss";
import Switch from "react-switch";
import PropTypes from "prop-types";

/**
 * Represents a toggle for amount or percentage.
 */
const AmountOrPercentageToggle = ({
  handleChange,
  amountOrPercentage,
  color = "#91989e",
}) => {
  return (
    <div className="amountOrPercentageToggle" style={{ color: color }}>
      <div>Amount</div>

      <Switch
        onChange={() =>
          handleChange(
            amountOrPercentage === "amount" ? "percentage" : "amount"
          )
        }
        checked={amountOrPercentage === "percentage"}
        className="switch"
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#649bff"
      />
      <div>Percentage</div>
    </div>
  );
};

AmountOrPercentageToggle.propTypes = {
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
};

export default AmountOrPercentageToggle;
