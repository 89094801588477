import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  portfolioPerformanceGetAnnualizedPerformanceOverPeriod,
  portfolioPerformanceGetAnnualizedPerformanceSinceInception,
  portfolioPerformanceGetPerformanceOverPeriod,
  portfolioPerformanceGetPerformanceSinceInception,
  portfolioPerformanceListMonthlyAndYearlyPerformanceSinceInception,
  portfolioPerformanceListMonthlyPerformanceCumulativeOverPeriod,
  portfolioPerformanceListMonthlyPerformanceOverPeriod,
} from "../../../api";
import { useAccount } from "../../../AccountContext";

const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const calculateDataForChart = (
  listMonthlyPerformanceCumulativeOverPeriodData,
  listMonthlyPerformanceOverPeriodData
) => {
  const data = [];

  listMonthlyPerformanceCumulativeOverPeriodData.forEach((item) => {
    const monthlyPerformanceOverPeriod = _.find(
      listMonthlyPerformanceOverPeriodData,
      ["YearMonth", item.YearMonth]
    );

    data.push({
      cumulativePerformance: item.Performance,
      monthlyPerformance: monthlyPerformanceOverPeriod.Performance,
      yearMonth: item.YearMonth,
    });
  });

  return data;
};

/**
 * Has the performance component logic.
 */
const usePerformance = () => {
  const accountId = useAccount();
  const currency = "EUR";
  const [calculationType, setCalculationType] = useState("TMW"); // TMW for Time Weighted, MMW for Money Weighted
  const [
    listMonthlyAndYearlyPerformanceSinceInception,
    setListMonthlyAndYearlyPerformanceSinceInception,
  ] = useState(false);
  const [performanceOverPeriod, setPerformanceOverPeriod] = useState(null);
  const [annualizedPerformanceOverPeriod, setAnnualizedPerformanceOverPeriod] =
    useState(null);
  const [performanceSinceInception, setPerformanceSinceInception] =
    useState(null);
  const [
    annualizedPerformanceSinceInception,
    setAnnualizedPerformanceSinceInception,
  ] = useState(null);
  const [performanceChartData, setPerformanceChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date(2000, 1, 1));
  const [endDate, setEndDate] = useState(new Date(Date.now()));

  useEffect(() => {
    const fetchData = async () => {
      const data =
        await portfolioPerformanceListMonthlyAndYearlyPerformanceSinceInception(
          accountId,
          currency,
          calculationType
        );

      const rows = [];
      const years = _.chain(data).map("Year").uniq().value();

      years.forEach((year) => {
        rows[year] = {
          Year: year,
        };
        months.forEach((month) => {
          const cell = _.find(data, ["YearMonth", `${year}-${month}`]);
          rows[year][month] = cell ? cell.Performance : "-";
        });

        const cell = _.find(data, ["YearMonth", `${year}-YTD`]);
        rows[year]["YTD"] = cell ? cell.Performance : "-";
      });

      setListMonthlyAndYearlyPerformanceSinceInception(rows);

      const startDateString =
        startDate.getFullYear() +
        startDate.getMonth().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        startDate.getDay().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });

      const endDateString =
        endDate.getFullYear() +
        endDate.getMonth().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        endDate.getDay().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });

      // performance over period
      const performanceOverPeriodData =
        await portfolioPerformanceGetPerformanceOverPeriod(
          accountId,
          currency,
          calculationType,
          startDateString,
          endDateString
        );

      setPerformanceOverPeriod(performanceOverPeriodData);

      // annualized performance over period
      const annualizedPerformanceOverPeriodData =
        await portfolioPerformanceGetAnnualizedPerformanceOverPeriod(
          accountId,
          currency,
          calculationType,
          startDateString,
          endDateString
        );

      setAnnualizedPerformanceOverPeriod(annualizedPerformanceOverPeriodData);

      // performance since inception
      const performanceSinceInceptionData =
        await portfolioPerformanceGetPerformanceSinceInception(
          accountId,
          currency,
          calculationType
        );

      setPerformanceSinceInception(performanceSinceInceptionData);

      // annualized performance since inception
      const annualizedPerformanceSinceInceptionData =
        await portfolioPerformanceGetAnnualizedPerformanceSinceInception(
          accountId,
          currency,
          calculationType
        );

      setAnnualizedPerformanceSinceInception(
        annualizedPerformanceSinceInceptionData
      );

      // list monthly performance cumulative over period
      const listMonthlyPerformanceCumulativeOverPeriodData =
        await portfolioPerformanceListMonthlyPerformanceCumulativeOverPeriod(
          accountId,
          currency,
          calculationType,
          startDateString,
          endDateString
        );

      // list monthly performance over period
      const listMonthlyPerformanceOverPeriodData =
        await portfolioPerformanceListMonthlyPerformanceOverPeriod(
          accountId,
          currency,
          calculationType,
          startDateString,
          endDateString
        );

      const performanceCalculatedData = calculateDataForChart(
        listMonthlyPerformanceCumulativeOverPeriodData,
        listMonthlyPerformanceOverPeriodData
      );

      setPerformanceChartData(performanceCalculatedData);

      setLoading(false);
    };

    fetchData();
  }, [accountId, calculationType, startDate, endDate]);

  return {
    calculationType,
    setCalculationType,
    listMonthlyAndYearlyPerformanceSinceInception,
    performanceOverPeriod,
    annualizedPerformanceOverPeriod,
    performanceSinceInception,
    annualizedPerformanceSinceInception,
    performanceChartData,
    loading,
    setLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    accountId,
    currency,
  };
};

export default usePerformance;
