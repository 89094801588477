import React, { useState } from "react";
import BoxSection from "../../../components/BoxSection";
import AssetsByAssetClass from "../../../components/charts/AssetsByAssetClass";
import TableAssetsByAssetClass from "../../../components/tables/AssetsByAssetClass";
import Debug from "../../Debug";
import AmountOrPercentageToggle from "../../AmountOrPercentageToggle";
import { Spinner } from "react-bootstrap";
import CustomPieChart from "../../charts/CustomPieChart";
import BoxGrey from "../../BoxGrey";
import useServicesAllocation from "./hook";

/**
 * Represents the desktop version of the services allocation component of the portfolio overview page.
 */
const ServicesAllocation = () => {
  const { accountId, loading, dataServices, dataCurrency, currency } = useServicesAllocation();

  const [amountOrPercentage, setAmountOrPercentage] = useState("amount");

  return (
    <BoxSection
      title="Services & Allocation"
      help="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quo hic vero, numquam neque in architecto tempore. Facilis, totam? Itaque, libero tempore est corporis ut ullam eaque? Abofficiis incidunt nobis!"
      id="portfolio-services-allocation"
    >
      {false && <p className="section-lead"> Nothing to show</p>}
      {loading && <Spinner animation="border" />}
      {!loading && (
        <>
          <AmountOrPercentageToggle
            handleChange={(value) => {
              setAmountOrPercentage(value);
            }}
            amountOrPercentage={amountOrPercentage}
          />

          <div className="row mb-4">
            <div className="col-sm-12 col-md-6">
              <BoxGrey title="Services">
                <CustomPieChart nameKey="ManagementTypeCategoryName" items={dataServices.Items} amountOrPercentage={amountOrPercentage} />
              </BoxGrey>
            </div>
            <div className="col-sm-12 col-md-6">
              <BoxGrey title="Currency">
                <CustomPieChart nameKey="CurrencySymbol" items={dataCurrency.Items} amountOrPercentage={amountOrPercentage} />
              </BoxGrey>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <div className="widget-light">
                <h3 className="widget-title">Asset Alocation</h3>
              </div>
            </div>
          </div>

          {true && (
            <div className="row">
              <div className="col">
                <TableAssetsByAssetClass />
              </div>
              <div className="col">
                <AssetsByAssetClass />
              </div>
            </div>
          )}
        </>
      )}

      <Debug apis={[`portfolio/ListAssetsByByManagementTypeCategory/${accountId}/${currency}`, `portfolio/ListAssetsByCurrency/${accountId}/${currency}`]}></Debug>
    </BoxSection>
  );
};

export default ServicesAllocation;
