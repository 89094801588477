import React, { useState } from "react";
import MobileSection from "../../MobileSection";
import useFixedIncome from "./hook";
import { Spinner } from "react-bootstrap";
import MobileCardWithAmountOrPercentageToggle from "../../MobileCardWithAmountOrPercentageToggle";
import CustomPieChart from "../../charts/CustomPieChart";
import MobileCard from "../../MobileCard";
import CustomBarChart from "../../charts/CustomBarChart";
import MobilePagination from "../../MobilePagination";

/**
 * Mobile version of the fixed income component.
 */
const MobileFixedIncome = () => {
  const {
    boxText,
    loading,
    dataCreditRisk,
    dataByGeography,
    dataByCurrency,
    dataByCreditRating,
    dataByProductType,
  } = useFixedIncome();
  const [
    creditAllocationAmountOrPercentage,
    setCreditAllocationAmountOrPercentage,
  ] = useState("amount");
  const [creditRatingAmountOrPercentage, setCreditRatingAmountOrPercentage] =
    useState("amount");
  const [
    structureByTypeOfProductAmountOrPercentage,
    setStructureByProductAmountOrPercentage,
  ] = useState("amount");
  const [
    structureByCurrencyAmountOrPercentage,
    setStructureByCurrencyAmountOrPercentage,
  ] = useState("amount");

  if (loading)
    return (
      <MobileSection
        title="Fixed Income"
        subtitle="label explaining what we are seeing"
      >
        <Spinner animation="border" />
      </MobileSection>
    );

  return (
    <div>
      <MobileSection
        title="Fixed Income"
        subtitle="label explaining what we are seeing"
        secondSubtitle={boxText}
      >
        <MobileCardWithAmountOrPercentageToggle
          title="Alocação por risco de crédito"
          amountOrPercentage={creditAllocationAmountOrPercentage}
          setAmountOrPercentage={setCreditAllocationAmountOrPercentage}
        >
          <CustomPieChart
            amountOrPercentage={creditAllocationAmountOrPercentage}
            items={dataCreditRisk.Items}
            nameKey="CreditRisk"
            innerRadius={30}
            outerRadius={60}
          />
        </MobileCardWithAmountOrPercentageToggle>

        <MobileCard title="Alocação por geografia (%)">
          <CustomBarChart
            items={dataByGeography.Items}
            amountOrPercentage="percentage"
            xKey="CountryName"
          />
        </MobileCard>

        <MobileCardWithAmountOrPercentageToggle
          title="Alocação por rating de crédito"
          subtitle="*Apenas investimentos directos"
          amountOrPercentage={creditRatingAmountOrPercentage}
          setAmountOrPercentage={setCreditRatingAmountOrPercentage}
        >
          <CustomPieChart
            amountOrPercentage={creditRatingAmountOrPercentage}
            items={dataByCreditRating.Items}
            nameKey="CreditRating"
            innerRadius={30}
            outerRadius={60}
          />
        </MobileCardWithAmountOrPercentageToggle>

        <MobileCardWithAmountOrPercentageToggle
          title="Structure by type of product"
          amountOrPercentage={structureByTypeOfProductAmountOrPercentage}
          setAmountOrPercentage={setStructureByProductAmountOrPercentage}
        >
          <CustomPieChart
            amountOrPercentage={structureByTypeOfProductAmountOrPercentage}
            items={dataByProductType.Items}
            nameKey="ProductType"
            innerRadius={30}
            outerRadius={60}
            layout="vertical"
          />
        </MobileCardWithAmountOrPercentageToggle>

        <MobileCardWithAmountOrPercentageToggle
          title="Structure by currency"
          subtitle="Click a currency to see risk metrics - only for direct investments, excluding funds and ETFs"
          amountOrPercentage={structureByCurrencyAmountOrPercentage}
          setAmountOrPercentage={setStructureByCurrencyAmountOrPercentage}
        >
          <CustomPieChart
            amountOrPercentage={structureByCurrencyAmountOrPercentage}
            items={dataByCurrency.Items}
            nameKey="CurrencySymbol"
            innerRadius={0}
            outerRadius={60}
            layout="vertical"
          />
        </MobileCardWithAmountOrPercentageToggle>
      </MobileSection>

      <MobilePagination
        leftText="Cash"
        leftLink="/portfolio-analysis"
        rightText="Equities"
        rightLink="/portfolio-analysis/equities"
      />
    </div>
  );
};

export default MobileFixedIncome;
