import { useState, useEffect } from "react";
import { useAccountUpdate } from "../../AccountContext";
import { listRootItems } from "../../api";

/**
 * Logic of the accounts component.
 */
const useAccounts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const accountUpdate = useAccountUpdate();

  useEffect(() => {
    const fetchData = async () => {
      const data = await listRootItems();

      setData(data.Items);

      if (data.Items.length) {
        accountUpdate(data.Items[0].Id);
      }

      setLoading(false);
    };

    fetchData();
  }, [accountUpdate]);

  return { data, loading };
};

export default useAccounts;
