import React, { Component } from "react";
import { FiArrowRight } from "react-icons/fi";
import Button from "react-bootstrap/Button";

/**
 * Represents the Login form.
 */
class LoginForm extends Component {
  state = {
    username: "",
    password: "",
    remember: true,
    hasErrors: false,
  };

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  toggleChange = () => {
    this.setState({
      remember: !this.state.remember,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ hasErrors: !this.state.hasErrors });
  };

  render() {
    return (
      <div className="login-form">
        <h3 className="title">
          Homebanking <br />
          Login
        </h3>

        <form noValidate onSubmit={this.handleSubmit} className={`${this.state.hasErrors ? "has-errors" : ""}`}>
          <div className="form-group">
            <label htmlFor="username">Username or Email</label>
            <input
              type="email"
              className="form-control form-control-line"
              id="username"
              aria-describedby="emailHelp"
              placeholder=""
              autoComplete="false"
              value={this.state.username}
              onChange={this.handleUsernameChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" className="form-control form-control-line" placeholder="" id="password" value={this.state.password} onChange={this.handlePasswordChange} />
          </div>
          <div className="form-group d-flex align-items-center justify-content-between">
            <div>
              <input type="checkbox" className="form-check-input" id="remember-me" checked={this.state.remember} onChange={this.toggleChange} />
              <label className="form-check-label" htmlFor="remember-me">
                Remember me
              </label>
            </div>

            <div>
              <a href="?">Forgot password?</a>
            </div>
          </div>

          <div className="d-flex align-items-center">
            {false && (
              <button type="submit" className="btn btn-pink d-flex justify-content-center align-items-center">
                Submit <FiArrowRight className="ml-2"></FiArrowRight>
              </button>
            )}

            <div className="ml-0">
              <a className="btn btn-pink d-flex justify-content-center align-items-center" href="/auth">
                Submit <FiArrowRight className="ml-2"></FiArrowRight>
              </a>
            </div>
          </div>

          <div className="error">* Invalid credentials</div>
        </form>

        <div className="disclaimer">
          By signing up, you agree with Millennium <a href="?">Terms & Conditions</a> and <a href="?">Privacy Policy</a>
        </div>
      </div>
    );
  }
}

export default LoginForm;
