import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  AiOutlineArrowRight,
  AiOutlineUpload,
  AiOutlineCloudUpload,
} from "react-icons/ai";
import { FaEnvelopeOpen } from "react-icons/fa";
import PersonalBanker from "../../components/PersonalBanker";
import UpcomingEvents from "../../components/UpcomingEvents";
import Search from "../../components/Search";

/**
 * Represents the MSafe sidebar.
 */
const Sidebar = ({ loading, categories }) => {
  return (
    <section
      className="bg-blue sidebar"
      style={{
        paddingTop: "100px",
        width: "100%",
      }}
    >
      <div style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
        <button
          className="btn btn-outline-primary"
          style={{
            display: "block",
            width: "100%",
            color: "white",
            borderColor: "white",
            backgroundColor: "#84AFFE",
            textAlign: "left",
          }}
        >
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <AiOutlineUpload
              style={{ fontSize: "20px", marginRight: "0.5rem" }}
            />{" "}
            Upload a file
          </div>
        </button>
        <button
          className="btn btn-outline-primary"
          style={{
            marginTop: "0.5rem",
            display: "block",
            width: "100%",
            color: "white",
            borderColor: "white",
            backgroundColor: "#84AFFE",
            textAlign: "left",
          }}
        >
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <FaEnvelopeOpen
              style={{ fontSize: "20px", marginRight: "0.5rem" }}
            />
            Request a report
          </div>
        </button>

        <div
          style={{
            backgroundColor: "#84AFFE",
            marginTop: "1rem",
            padding: "0.2rem",
            paddingLeft: "2rem",
            color: "white",
            borderRadius: "30px",
            fontSize: "15px",
            fontWeight: "bold",
          }}
        >
          MSafe
        </div>

        {loading && (
          <Spinner animation="border" style={{ marginTop: "1rem" }} />
        )}
        {!loading && (
          <div style={{ marginTop: "1rem" }}>
            <div className="row">
              <div className="col col-2">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: "bold",
                  }}
                >
                  <AiOutlineArrowRight style={{ fontSize: "20px" }} />
                </div>
              </div>
              <div className="col col-10" style={{ fontWeight: "bold" }}>
                All
              </div>
            </div>
            {categories.map((category, index) => (
              <div className="row" key={index}>
                <div className="col col-2"></div>
                <div className="col col-10">{category.Name}</div>
              </div>
            ))}
          </div>
        )}

        <button
          className="btn btn-outline-primary"
          style={{
            color: "white",
            width: "100%",
            borderColor: "white",
            marginTop: "1rem",
            textAlign: "left",
          }}
        >
          <AiOutlineCloudUpload
            style={{ fontSize: "20px", marginRight: "0.5rem" }}
          />
          Request upgrade
        </button>

        <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <PersonalBanker
            name="Justin Merville"
            avatar="/images/justin-merville.png"
            status="online"
          />
        </div>
      </div>

      <UpcomingEvents />

      <Search />
    </section>
  );
};

Sidebar.propTypes = {
  loading: PropTypes.bool.isRequired,
  categories: PropTypes.array,
};

export default Sidebar;
