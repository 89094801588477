import React, { useState, useEffect } from "react";

/**
 * Represents the debug component.
 */
const Debug = ({ children, apis = [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleInfo = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className="debug">
      <div className="d-flex">
        <a
          href="/"
          className={isOpen ? "info active" : "info"}
          onClick={handleInfo}
        >
          +Info
        </a>
      </div>
      {isOpen && (
        <pre>
          <ul>
            {apis.map((api, n) => {
              const link = api.replace(/^portfolio\//, "/Portfolio/Portfolio_");

              return (
                <li key={n}>
                  <a
                    target="_blank"
                    href={`https://www2.millenniumbp.ch/api/documentation/ui/index#!${link}`}
                  >
                    {api}
                  </a>
                </li>
              );
            })}
          </ul>
        </pre>
      )}
    </div>
  );
};

export default Debug;
