import React, { useState } from "react";

/**
 * Represents the mobile toolbox component.
 */
const MobileToolbox = ({ onOpenMenu }) => {
  const [items, setItems] = useState([
    {
      key: "overview",
      label: "Portfolio Overview",
      url: "/",
      active: true,
    },
    {
      key: "analysis",
      label: "Portfolio Analysis",
      url: "/",
    },
    {
      key: "trade",
      label: "Trade",
      url: "/",
    },
    {
      key: "transfer",
      label: "Transfer",
      url: "/",
    },
  ]);

  const handleClick = (current_item) => {
    setItems(
      items.map((item) => {
        delete item.active;
        if (item.key === current_item.key) {
          item.active = true;
        }
        return item;
      })
    );
  };

  return (
    <div className="mobile-toolbox d-flex d-md-none justify-content-between">
      <ul className="menu">
        {items.map((item) => (
          <li key={item.key}>
            <a
              className={item.key + " " + (item.active ? "active" : "")}
              href={item.url}
              onClick={(e) => {
                e.preventDefault();
                handleClick(item);
              }}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul>

      <a className="btn-menu" href="?" onClick={onOpenMenu}>
        <svg
          width="125"
          height="125"
          viewBox="0 0 125 125"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="62.9338" cy="62.8415" r="61.9938" fill="#649BFF" />
          <line
            x1="38.3812"
            y1="43.7693"
            x2="89.2094"
            y2="43.7693"
            stroke="white"
            strokeOpacity="0.7"
            strokeWidth="5.9994"
            strokeLinecap="round"
          />
          <path
            d="M50.0184 53.6577H89.0515"
            stroke="white"
            strokeWidth="5.9994"
            strokeLinecap="round"
          />
          <path
            d="M38.538 53.6577H40.8347"
            stroke="white"
            strokeWidth="5.9994"
            strokeLinecap="round"
          />
          <path
            d="M50.0184 62.8418H89.0515"
            stroke="white"
            strokeWidth="5.9994"
            strokeLinecap="round"
          />
          <line
            x1="38.3806"
            y1="85.4393"
            x2="89.2088"
            y2="85.4393"
            stroke="white"
            strokeOpacity="0.7"
            strokeWidth="5.9994"
            strokeLinecap="round"
          />
          <line
            x1="38.3806"
            y1="74.0755"
            x2="89.2088"
            y2="74.0755"
            stroke="white"
            strokeOpacity="0.7"
            strokeWidth="5.9994"
            strokeLinecap="round"
          />
        </svg>
      </a>
    </div>
  );
};

export default MobileToolbox;
