import React from "react";
import MobileSection from "../../MobileSection";
import MobileCard from "../../MobileCard";
import MobilePagination from "../../MobilePagination";

/**
 * Mobile version of the portfolio details component of the portfolio overview page.
 */
const MobilePortfolioDetails = () => {
  return (
    <div style={{ marginTop: "1rem" }}>
      <MobileSection
        title="Portfolio Details"
        subtitle="A label lorem lorem lorem lorem, Click the lorem lorem lorem lorem."
      >
        <MobileCard>
          <div
            style={{
              padding: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button className="btn btn-outline-primary">
              See pending transactions
            </button>
          </div>
        </MobileCard>
      </MobileSection>

      <MobilePagination
        leftLink="/portfolio"
        leftText="Services & Allocation"
        rightLink="/portfolio/performance"
        rightText="Performance"
      />
    </div>
  );
};

export default MobilePortfolioDetails;
