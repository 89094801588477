import { useEffect, useState } from "react";
import { useAccount } from "../../../AccountContext";
import { formatMoney } from "../../../utils/format";
import {
  portfolioGetTotalAssetClass,
  portfolioListPortfolioByCurrency,
  portfolioListPortfolioByMarketExposure,
  portfolioListPortfolioLines,
} from "../../../api";

/**
 * Has the logic of the alternative invesments component.
 */
const useAlternativeInvestments = () => {
  const currency = "EUR";
  const assetClass = "8";
  const accountId = useAccount();
  const [boxText, setBoxText] = useState(false);
  const [loading, setLoading] = useState(true);

  const [dataPortfolioLines, setDataPortfolioLines] = useState(null);
  const [dataByCurrency, setDataByCurrency] = useState(null);
  const [dataByMarketExposure, setDataByMarketExposure] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const totalAssetClassData = await portfolioGetTotalAssetClass(
        accountId,
        currency,
        assetClass
      );

      const formattedVal = formatMoney(parseFloat(totalAssetClassData));
      setBoxText(`TOTAL ALTERNATIVE INVESTMENTS: ${formattedVal} ${currency}`);

      // 5 Maiores Posições
      const portfolioLinesData = await portfolioListPortfolioLines(
        accountId,
        currency,
        assetClass
      );

      setDataPortfolioLines(portfolioLinesData);

      // Exposição cambial
      const portfolioByCurrencyData = await portfolioListPortfolioByCurrency(
        accountId,
        currency,
        assetClass
      );

      setDataByCurrency(portfolioByCurrencyData);

      // Alocação por estratégia
      const portfolioByMarketExposureData =
        await portfolioListPortfolioByMarketExposure(
          accountId,
          currency,
          assetClass
        );

      setDataByMarketExposure(portfolioByMarketExposureData);

      setLoading(false);
    };

    fetchData();
  }, [accountId, currency]);

  return {
    currency,
    accountId,
    boxText,
    loading,
    dataPortfolioLines,
    dataByCurrency,
    dataByMarketExposure,
  };
};

export default useAlternativeInvestments;
