import React, { useEffect, useState } from "react";
import {
  portfolioGetTotalAssetClass,
  portfolioListPortfolioByCurrency,
} from "../../../api";
import { useAccount } from "../../../AccountContext";
import {
  formatMoney,
  transformAmountOrPercentage,
} from "../../../utils/format";
import { colors } from "../../../utils/colors";
import _ from "lodash";

/**
 * Has the logic of the cash component.
 */
const useCash = () => {
  const currency = "EUR";
  const assetClass = "1";
  const accountId = useAccount();
  const [boxText, setBoxText] = useState(false);
  const [dataCurrency, setDataCurrency] = useState(false);
  const [chartData, setChartData] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const totalAssetClassData = await portfolioGetTotalAssetClass(
        accountId,
        currency,
        assetClass
      );

      const formattedVal = formatMoney(parseFloat(totalAssetClassData));
      setBoxText(`TOTAL CASH: ${formattedVal} ${currency}`);

      const portfolioByCurrencyData = await portfolioListPortfolioByCurrency(
        accountId,
        currency,
        assetClass
      );

      setDataCurrency(portfolioByCurrencyData.Items);

      setLoading(false);
    };
    fetchData();
  }, [accountId, currency]);

  return {
    currency,
    accountId,
    boxText,
    setBoxText,
    dataCurrency,
    setDataCurrency,
    chartData,
    loading,
  };
};

export default useCash;
