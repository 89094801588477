import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/**
 * Represents a custom date picker.
 */
const CustomDatePicker = ({ date, setDate }) => {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="btn btn-outline-secondary"
      onClick={onClick}
      ref={ref}
      style={{
        margin: "0.5rem",
        borderRadius: "20px",
        color: "#C4C4C4",
        borderColor: "#C4C4C4",
      }}
    >
      {value}
    </button>
  ));

  return (
    <DatePicker
      selected={date}
      onChange={(newDate) => setDate(newDate)}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      showFullMonthYearPicker
      customInput={<CustomInput />}
    />
  );
};

export default CustomDatePicker;
