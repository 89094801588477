import React from "react";
import PropTypes from "prop-types";

/**
 * Represents the mobile section.
 */
const MobileSection = ({ title, subtitle, secondSubtitle, children }) => {
  return (
    <div>
      <div style={{ color: "#91989E", fontSize: "20px", fontWeight: "bold" }}>
        {title}
      </div>
      {subtitle && (
        <div style={{ color: "#91989E", fontSize: "14px" }}>{subtitle}</div>
      )}

      {secondSubtitle && (
        <div
          style={{
            color: "#91989E",
            fontSize: "16px",
            marginTop: "0.5rem",
            fontWeight: "bold",
          }}
        >
          {secondSubtitle}
        </div>
      )}

      {children}
    </div>
  );
};

MobileSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  secondSubtitle: PropTypes.string,
};

export default MobileSection;
