import React, { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import AppFooter from "../components/AppFooter";
import Sidebar from "../components/Sidebar";
import Performance from "../components/Dashboard/Performance/Desktop";
import Agenda from "../components/Dashboard/Agenda/Desktop";
import CashFlowProjection from "../components/Dashboard/CashFlowProjection/Desktop";
import AssetAllocationEvolution from "../components/Dashboard/AssetAllocationEvolution/Desktop";
import PortfolioDetails from "../components/Dashboard/PortfolioDetails/Desktop";
import ServicesAllocation from "../components/Dashboard/ServicesAllocation/Desktop";
import Accounts from "../components/Accounts/Desktop";
import { testSecret } from "../api";
import Spinner from "react-bootstrap/Spinner";
import useIsMobile from "../hooks/isMobileHook";
import { Route } from "react-router-dom";
import ServicesAllocationMobile from "../components/Dashboard/ServicesAllocation/Mobile";
import PortfolioDetailsMobile from "../components/Dashboard/PortfolioDetails/Mobile";
import PerformanceMobile from "../components/Dashboard/Performance/Mobile";
import AssetAllocationEvolutionMobile from "../components/Dashboard/AssetAllocationEvolution/Mobile";
import AgendaMobile from "../components/Dashboard/Agenda/Mobile";
import CashFlowProjectionMobile from "../components/Dashboard/CashFlowProjection/Mobile";
import AccountsMobile from "../components/Accounts/Mobile";
import { AiOutlineMenu } from "react-icons/ai";
import { Dropdown } from "react-bootstrap";
import MobileNavigation from "../components/MobileNavigation";

/**
 * Represents the Portfolio Overview page.
 */
const PortfolioOverview = () => {
  const [loading, setLoading] = useState(true);
  const isMobile = useIsMobile();

  useEffect(() => {
    testSecret()
      .then(() => {})
      .catch((error) => {
        // window.location.href = "/";
      })
      .finally(() => {
        setLoading(false);
      });

    // const script = document.createElement("script");
    // script.src = "https://mbpv.acp.unblu-env.com/unblu/visitor.js?x-unblu-apikey=FAxvpmCgRIiy1Sv8sk9vSw";
    // script.async = true;
    // document.body.appendChild(script);

    return () => {
      // document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <div className="flex-fill content-wrapper">
          {loading && <Spinner animation="border" />}

          {!loading && !isMobile && (
            <>
              <div className="row">
                <div className="col col-3" style={{ marginTop: "-1.5rem" }}>
                  <Sidebar />
                </div>
                <div className="col col-9">
                  <Accounts />

                  <ServicesAllocation />

                  <PortfolioDetails />

                  <Performance />

                  <Waypoint
                    onEnter={() => {
                      // console.log("onEnter");
                    }}
                    onLeave={() => {
                      // console.log("onLeave");
                    }}
                  />

                  <AssetAllocationEvolution />

                  <Agenda />

                  <CashFlowProjection />
                </div>
              </div>
            </>
          )}

          {!loading && isMobile && (
            <div style={{ padding: "0.5rem" }}>
              <div className="row">
                <div className="col col-10">
                  <AccountsMobile />
                </div>
                <div className="col col-2">
                  <MobileNavigation
                    links={[
                      { name: "Services & Allocation", url: "/portfolio" },
                      {
                        name: "Portfolio Details",
                        url: "/portfolio/portfolio-details",
                      },
                      { name: "Performance", url: "/portfolio/performance" },
                      {
                        name: "Asset allocation evolution",
                        url: "/portfolio/asset-allocation-evolution",
                      },
                      { name: "Agenda", url: "/portfolio/agenda" },
                      {
                        name: "Cash flow projection",
                        url: "/portfolio/cash-flow-projection",
                      },
                    ]}
                  />
                </div>
              </div>

              <Route exact path="/portfolio">
                <ServicesAllocationMobile />
              </Route>
              <Route path="/portfolio/portfolio-details">
                <PortfolioDetailsMobile />
              </Route>
              <Route path="/portfolio/performance">
                <PerformanceMobile />
              </Route>
              <Route path="/portfolio/asset-allocation-evolution">
                <AssetAllocationEvolutionMobile />
              </Route>
              <Route path="/portfolio/agenda">
                <AgendaMobile />
              </Route>
              <Route path="/portfolio/cash-flow-projection">
                <CashFlowProjectionMobile />
              </Route>
            </div>
          )}
        </div>
        <AppFooter></AppFooter>
      </div>
    </div>
  );
};

export default PortfolioOverview;
