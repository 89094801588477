import React, { useEffect, useState } from "react";
import BoxSection from "../../BoxSection";
import { Spinner } from "react-bootstrap";
import AmountOrPercentageToggle from "../../AmountOrPercentageToggle";
import CustomPieChart from "../../charts/CustomPieChart";
import BoxGrey from "../../BoxGrey";
import useCash from "./hook";

/**
 * Represents the desktop version of the cash component of the portfolio analysis page.
 */
const Cash = () => {
  const [amountOrPercentage, setAmountOrPercentage] = useState("amount");
  const { loading, boxText, dataCurrency } = useCash();

  return (
    <BoxSection title="Cash" text={boxText} id="portfolio-analysis-cash">
      {loading && <Spinner animation="border" />}
      {!loading && (
        <div>
          <div className="row">
            <div className="col">
              <p className="section-lead">label explaining what we are seing</p>
            </div>
            <div className="col">
              <AmountOrPercentageToggle
                handleChange={(value) => {
                  setAmountOrPercentage(value);
                }}
                amountOrPercentage={amountOrPercentage}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <BoxGrey title="Exposure by type of instrument">
                <p className="text-center mt-5">API Method not provided</p>
              </BoxGrey>
            </div>
            <div className="col-sm-12 col-md-6">
              <BoxGrey title="Exposure by currency">
                <CustomPieChart nameKey="CurrencySymbol" items={dataCurrency} amountOrPercentage={amountOrPercentage} layout="vertical" />
              </BoxGrey>
            </div>
          </div>

          {dataCurrency && false && (
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>CurrencyId</th>
                    <th>CurrencySymbol</th>
                    <th>Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {dataCurrency.map((row) => (
                    <tr key={row.CurrencyId}>
                      <td>{row.Amount}</td>
                      <td>{row.CurrencyId}</td>
                      <td>{row.CurrencySymbol}</td>
                      <td>{row.Percentage}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </BoxSection>
  );
};

export default Cash;
