/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *
 */

import axios from "axios";

axios.defaults.crossDomain = true;

/**
 * Create an Axios Client with defaults
 */
const token = localStorage.getItem("token");
const client = axios.create({
  // baseURL: "https://www2.millenniumbp.ch",
  baseURL: "/",
  mode: "cors",
  method: "get",
  headers: {
    //"Access-Control-Allow-Origin": "*",
    //"Access-Control-Allow-Origin": "*",
    //"Content-Type": "application/json",
    //crossDomain: true,
    //Origin: "localhost",
    //"Access-Control-Allow-Credentials": true,
    //Foo: "Bar",
    //Origin: "http://localhost:3000",
    Authorization: `Bearer ${token}`,
  },
});

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  const onSuccess = function (response) {
    // console.debug("Request Successful!", response);
    return response.data;
  };

  const onError = function (error) {
    // console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      // console.error("Status:", error.response.status);
      // console.error("Data:", error.response.data);
      // console.error("Headers:", error.response.headers);
      if (+error.response.status === 401) {
        console.log("Login required");
        window.location.href = "/";
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      // console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
