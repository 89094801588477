import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import PropTypes from "prop-types";
import { roundPercentage, roundNumber } from "../../utils/format";

/**
 * Custom label of the custom bar chart.
 */
const CustomLabel = (props) => {
  const { x, y, width, height, value, amountOrPercentage } = props;

  return (
    <text
      x={x + width}
      y={y + height / 2}
      textAnchor="right"
      dominantBaseline="middle"
      fontSize="10px"
    >
      {amountOrPercentage === "amount"
        ? roundNumber(value).toLocaleString()
        : `${roundPercentage(value)}%`}
    </text>
  );
};

/**
 * Represents a custom bar chart.
 */
const CustomBarChart = ({ amountOrPercentage, items, xKey }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={items} layout="vertical">
        <XAxis hide type="number" axisLine={false} />
        <YAxis type="category" dataKey={xKey} fontSize={10} interval={0} />
        <Tooltip
          formatter={(value) => {
            if (amountOrPercentage === "amount") {
              return roundNumber(value).toLocaleString();
            } else {
              return `${roundPercentage(value)}%`;
            }
          }}
        />
        <Bar
          dataKey={amountOrPercentage === "amount" ? "Amount" : "Percentage"}
          fill="#649bff"
          label={<CustomLabel amountOrPercentage={amountOrPercentage} />}
          isAnimationActive={false}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

CustomBarChart.propTypes = {
  amountOrPercentage: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  xKey: PropTypes.string.isRequired,
};

export default CustomBarChart;
