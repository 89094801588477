import request from "./request";

const baseURL = "https://banqueprivee.excentric.id";

/* Test */

export const testSecret = () => request(`${baseURL}/api/Test/Secret`);
export const testPublic = () => request(`${baseURL}/api/Test/Public`);

/* Root */

export const listRootItems = () =>
  request({
    url: `${baseURL}/api/Roots/ListRootItems`,
  });

export const getRootCard = (id) =>
  request({
    url: `api${baseURL}/api/Roots/GetRootCard/${id}`,
  });

/* Portfolio */

export const portfolioListAssetsByAssetClass = (accountId, currency) =>
  request({
    url: `${baseURL}/api/portfolio/ListAssetsByAssetClass/${accountId}/${currency}`,
  });

export const portfolioListAssetsByCurrency = (accountId, currency) =>
  request({
    url: `${baseURL}/api/portfolio/ListAssetsByCurrency/${accountId}/${currency}`,
  });

export const portfolioListAssetsByManagementTypeCategory = (accountId, currency) =>
  request({
    url: `${baseURL}/api/portfolio/ListAssetsByByManagementTypeCategory/${accountId}/${currency}`,
  });

export const portfolioListPortfolioAgendaLines = (accountId) =>
  request({
    url: `${baseURL}/api/portfolio/ListPortfolioAgendaLines/${accountId}`,
  });

export const portfolioListPortfolioByMonth = (accountId, currency, dateRange) =>
  request({
    url: `${baseURL}/api/portfolio/ListPortfolioByMonth/${accountId}/${currency}/${dateRange}`,
  });

export const portfolioListPortfolioByAssetClassByMonth = (accountId, currency, dateRange) =>
  request({
    url: `${baseURL}/api/portfolio/ListPortfolioByAssetClassByMonth/${accountId}/${currency}/${dateRange}`,
  });

export const portfolioListCashFlowLines = (accountId, currency) =>
  request({
    url: `${baseURL}/api/portfolio/ListCashFlowLines/${accountId}/${currency}`,
  });

export const portfolioGetTotalAssetClass = (accountId, currency, assetClass) => request(`${baseURL}/api/portfolio/GetTotalAssetClass/${accountId}/${currency}/1/${assetClass}`);

export const portfolioListPortfolioLines = (accountId, currency, assetClass) =>
  request(`${baseURL}/api/portfolio/ListPortfolioLines/${accountId}/${currency}/1/${assetClass}?$top=5&$orderby=percentage%20desc`);

export const portfolioListPortfolioByCurrency = (accountId, currency, assetClass) => request(`${baseURL}/api/portfolio/ListPortfolioByCurrency/${accountId}/${currency}/1/${assetClass}`);

export const portfolioListPortfolioByMarketExposure = (accountId, currency, assetClass) => request(`${baseURL}/api/portfolio/ListPortfolioByMarketExposure/${accountId}/${currency}/1/${assetClass}`);

export const portfolioListPortfolioByGeography = (accountId, currency, assetClass) => request(`${baseURL}/api/portfolio/ListPortfolioByGeography/${accountId}/${currency}/1/${assetClass}`);

export const portfolioListPortfolioByProductType = (accountId, currency, assetClass) => request(`${baseURL}/api/portfolio/ListPortfolioByProductType/${accountId}/${currency}/1/${assetClass}`);

export const portfolioListPortfolioByCreditRisk = (accountId, currency, assetClass) => request(`${baseURL}/api/portfolio/ListPortfolioByCreditRisk/${accountId}/${currency}/1/${assetClass}`);

export const portfolioListPortfolioByCreditRating = (accountId, currency, assetClass) => request(`${baseURL}/api/portfolio/ListPortfolioByCreditRating/${accountId}/${currency}/1/${assetClass}`);

export const portfolioGetTotalAssets = (accountId, currency) =>
  request({
    url: `${baseURL}/api/portfolio/GetTotalAssets/${accountId}/${currency}`,
  });

export const portfolioGetTotalLiabilities = (accountId, currency) =>
  request({
    url: `${baseURL}/api/portfolio/GetTotalLiabilities/${accountId}/${currency}`,
  });

/* Portfolio Analysis */

export const portfolioAnalysisGetPortfolioCheckCurrencyExposure = (accountId) =>
  request({
    url: `${baseURL}/api/portfolioAnalysis/GetPortfolioCheckCurrencyExposure/${accountId}`,
  });

export const portfolioAnalysisGetPortfolioCheckAllocation = (accountId) =>
  request({
    url: `${baseURL}/api/portfolioAnalysis/GetPortfolioCheckAllocation/${accountId}`,
  });

export const portfolioAnalysisGetPortfolioCheckPortfolioRisk = (accountId) =>
  request({
    url: `${baseURL}/api/portfolioAnalysis/GetPortfolioCheckPortfolioRisk/${accountId}`,
  });

export const portfolioAnalysisGetPortfolioCheckSectorExposure = (accountId) =>
  request({
    url: `${baseURL}/api/portfolioAnalysis/GetPortfolioCheckSectorExposure/${accountId}`,
  });

export const portfolioAnalysisGetPortfolioCheckIssuerExposure = (accountId) =>
  request({
    url: `${baseURL}/api/portfolioAnalysis/GetPortfolioCheckIssuerExposure/${accountId}`,
  });

/* Portfolio Performance */

export const portfolioPerformanceListMonthlyAndYearlyPerformanceSinceInception = (accountId, currency, calculationType) =>
  request({
    url: `${baseURL}/api/portfolioperformance/ListMonthlyAndYearlyPerformanceSinceInception/${accountId}/${currency}/${calculationType}`,
  });

export const portfolioPerformanceGetPerformanceOverPeriod = (accountId, currency, type, startDate, endDate) =>
  request({
    url: `${baseURL}/api/portfolioperformance/GetPerformanceOverPeriod/${accountId}/${currency}/${type}/${startDate}/${endDate}`,
  });

export const portfolioPerformanceGetAnnualizedPerformanceOverPeriod = (accountId, currency, type, startDate, endDate) =>
  request({
    url: `${baseURL}/api/portfolioperformance/GetAnnualizedPerformanceOverPeriod/${accountId}/${currency}/${type}/${startDate}/${endDate}`,
  });

export const portfolioPerformanceGetPerformanceSinceInception = (accountId, currency, type) =>
  request({
    url: `${baseURL}/api/portfolioperformance/GetPerformanceSinceInception/${accountId}/${currency}/${type}`,
  });

export const portfolioPerformanceGetAnnualizedPerformanceSinceInception = (accountId, currency, type) =>
  request({
    url: `${baseURL}/api/portfolioperformance/GetAnnualizedPerformanceSinceInception/${accountId}/${currency}/${type}`,
  });

export const portfolioPerformanceListMonthlyPerformanceCumulativeOverPeriod = (accountId, currency, type, startDate, endDate) =>
  request({
    url: `${baseURL}/api/portfolioperformance/ListMonthlyPerformanceCumulativeOverPeriod/${accountId}/${currency}/${type}/${startDate}/${endDate}`,
  });

export const portfolioPerformanceListMonthlyPerformanceOverPeriod = (accountId, currency, type, startDate, endDate) =>
  request({
    url: `${baseURL}/api/portfolioperformance/ListMonthlyPerformanceOverPeriod/${accountId}/${currency}/${type}/${startDate}/${endDate}`,
  });

/* MSafe */

export const msafeListFileCategories = () =>
  request({
    url: `${baseURL}/api/msafe/ListFileCategories`,
  });

export const msafeList = () =>
  request({
    url: `${baseURL}/api/msafe/List`,
  });
