import { useState, useEffect } from "react";
import { useAccount } from "../../../AccountContext";
import { formatMoney } from "../../../utils/format";
import {
  portfolioAnalysisGetPortfolioCheckAllocation,
  portfolioAnalysisGetPortfolioCheckCurrencyExposure,
  portfolioAnalysisGetPortfolioCheckIssuerExposure,
  portfolioAnalysisGetPortfolioCheckPortfolioRisk,
  portfolioAnalysisGetPortfolioCheckSectorExposure,
  portfolioGetTotalAssetClass,
} from "../../../api";
import _ from "lodash";

/**
 * This hooks have the logic of the portfolio check component.
 */
const usePortfolioCheck = () => {
  const currency = "EUR";
  const accountId = useAccount();
  const [boxText, setBoxText] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentExposure, setCurrentExposure] = useState(null);
  const [currentExposureChart, setCurrentExposureChart] = useState(null);
  const [allocation, setAllocation] = useState(null);
  const [allocationChart, setAllocationChart] = useState(null);
  const [risk, setRisk] = useState(null);
  const [sectorExposure, setSectorExposure] = useState(null);
  const [sectorExposureTable, setSectorExposureTable] = useState(null);
  const [issuerExposure, setIssuerExposure] = useState(null);
  const [issuerExposureTable, setIssuerExposureTable] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const totalAssetClassData = await portfolioGetTotalAssetClass(
        accountId,
        currency,
        "1"
      );

      const formattedVal = formatMoney(parseFloat(totalAssetClassData));
      setBoxText(`TOTAL FIXED INCOME: ${formattedVal} ${currency}`);

      // exposição cambial
      const currentExposureData =
        await portfolioAnalysisGetPortfolioCheckCurrencyExposure(accountId);

      setCurrentExposure(currentExposureData);

      const currentExposureChartData = _.map(
        currentExposureData.Currencies,
        (value, currency) => ({
          currency: currency,
          Percentage: value,
        })
      );

      setCurrentExposureChart(currentExposureChartData);

      // alocação de ativos
      const allocationData = await portfolioAnalysisGetPortfolioCheckAllocation(
        accountId
      );

      setAllocation(allocationData);

      // indicador de risco do portfolio
      const portfolioRiskData =
        await portfolioAnalysisGetPortfolioCheckPortfolioRisk(accountId);

      setRisk(portfolioRiskData);

      // exposição por setor
      const sectorExposureData =
        await portfolioAnalysisGetPortfolioCheckSectorExposure(accountId);

      setSectorExposure(sectorExposureData);

      const sectorExposureTableData = _.map(
        sectorExposureData.Sectors,
        (value, index) => ({
          value: value,
          index: index,
        })
      );

      setSectorExposureTable(sectorExposureTableData);

      // exposição por emitentes
      const issuerExposureData =
        await portfolioAnalysisGetPortfolioCheckIssuerExposure(accountId);

      setIssuerExposure(issuerExposureData);

      const issuerExposureTableData = _.map(
        issuerExposureData.Issuers,
        (value, index) => ({ value: value, index: index })
      );

      setIssuerExposureTable(issuerExposureTableData);

      setLoading(false);
    };

    fetchData();
  }, [accountId, currency]);

  return {
    currency,
    accountId,
    boxText,
    loading,
    currentExposure,
    currentExposureChart,
    allocation,
    allocationChart,
    setAllocationChart,
    risk,
    sectorExposure,
    sectorExposureTable,
    issuerExposure,
    issuerExposureTable,
  };
};

export default usePortfolioCheck;
