import Help from "../assets/images/help.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAccount } from "../AccountContext";

/**
 * Represents a box section.
 */
const BoxSection = ({ children, title, text = false, help, id = false }) => {
  const accountId = useAccount();
  const displayCurrrentAccount = true;

  return (
    <section
      className="container-fluid shadow p-3 mb-5 bg-white d-block w-full"
      id={id ? id : ""}
    >
      <div className="section-title-container">
        <div className="flex-grow-1">
          <h2 className="section-title">
            {title} 
            {displayCurrrentAccount && (
              <span className="account">({accountId})</span>
            )}
          </h2>
        </div>
        {text && <div className="section-title-text">{text}</div>}

        {help && (
          <OverlayTrigger
            placement="bottom-end"
            overlay={<Tooltip>{help}</Tooltip>}
          >
            <span className="section-help">
              <img src={Help} alt="Help" />
            </span>
          </OverlayTrigger>
        )}
      </div>

      <div className="section-content">{children}</div>
    </section>
  );
};

export default BoxSection;
