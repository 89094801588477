import React, { useEffect, useState } from "react";
import { useAccount } from "../../AccountContext";
import { portfolioGetTotalAssets, portfolioGetTotalLiabilities, portfolioListAssetsByAssetClass } from "../../api";
import { Spinner } from "react-bootstrap";

/**
 * Represents the assets by asset class table.
 */
const AssetsByAssetClass = () => {
  const accountId = useAccount();

  const currency = "EUR";

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [totalAssets, setTotalAssets] = useState(false);
  const [totalLiabilities, setTotalLiabilities] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      console.log(accountId);

      const data = await portfolioListAssetsByAssetClass(accountId, currency);
      setData(data.Items);

      const totalAssets = portfolioGetTotalAssets(accountId, currency);
      setTotalAssets(totalAssets);

      const totalLiabilities = await portfolioGetTotalLiabilities(accountId, currency);
      setTotalLiabilities(totalLiabilities);

      setLoading(false);
    };

    fetchData();
  }, [accountId]);

  return (
    <div>
      {loading && <Spinner animation="border" />}
      {!loading && data && (
        <table className="table table-sm table-mbp">
          <thead>
            <tr className="heading-grey">
              <th>Assets</th>
              <th>Amount</th>
              <th>% of portfolio</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cash</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Equities</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Alternative Investments</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Other Investments</td>
              <td></td>
              <td></td>
            </tr>
            <tr className="total">
              <td>Total</td>
              <td></td>
              <td></td>
            </tr>

            {data.map((row) => (
              <tr key={row.AssetClassId}>
                <td>{row.AssetClassName}</td>
                <td>{row.Amount}</td>
                <td>{row.Percentage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {false && (
        <>
          {totalAssets !== false && <div>totalAssets: {totalAssets}</div>}

          {totalLiabilities !== false && <div>totalLiabilities: {totalLiabilities}</div>}
        </>
      )}
    </div>
  );
};

export default AssetsByAssetClass;
