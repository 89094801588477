import React from "react";
import { Spinner } from "react-bootstrap";
import MobileCard from "../../MobileCard";
import MobileSection from "../../MobileSection";
import useAgenda from "./hook";
import { formatDate, formatMoney } from "../../../utils/format";
import Pagination from "../../../components/Pagination";
import MobilePagination from "../../MobilePagination";
import MobileTable from "../../MobileTable";

const tableHeaders = [
  { name: "Operation", value: "Interest" },
  { name: "Due date", value: "NextPaymentDate" },
  { name: "Currency", value: "CurrencySymbolId" },
  { name: "Amount", value: "Amount" },
];

/**
 * Mobile version of the agenda component of the portfolio overview page.
 */
const MobileAgenda = () => {
  const {
    loading,
    currentRows,
    currentPage,
    postsPerPage,
    data,
    handleChangePage,
  } = useAgenda();

  return (
    <div style={{ marginTop: "1rem" }}>
      <MobileSection
        title="Agenda"
        subtitle="Values are presented in security local currency"
      >
        {loading && <Spinner animation="border" />}
        {!loading && (
          <MobileCard>
            {!currentRows.length && (
              <p className="empty-result">Nothing to show</p>
            )}
            {!!currentRows.length && (
              <div>
                <MobileTable
                  tableHeaders={tableHeaders}
                  rows={currentRows}
                  header="Security"
                />

                <Pagination
                  currentPage={currentPage}
                  postsPerPage={postsPerPage}
                  items={data}
                  onChangePage={handleChangePage}
                />

                <div
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    marginLeft: "-0.5rem",
                    marginRight: "-0.5rem",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "rgba(100, 155, 255, 0.1)",
                    padding: "0.5rem",
                  }}
                >
                  <button
                    className="btn btn-outline-primary"
                    style={{ width: "80%" }}
                  >
                    Filters
                  </button>
                </div>
              </div>
            )}
          </MobileCard>
        )}
      </MobileSection>

      <MobilePagination
        leftLink="/portfolio/asset-allocation-evolution"
        leftText="Asset allocation evolution"
        rightLink="/portfolio/cash-flow-projection"
        rightText="Cash flow projection"
      />
    </div>
  );
};

export default MobileAgenda;
