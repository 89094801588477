import React, { useEffect, useState } from "react";
import BoxSection from "../../BoxSection";
import { Spinner } from "react-bootstrap";
import AmountOrPercentageToggle from "../../AmountOrPercentageToggle";
import CustomPieChart from "../../charts/CustomPieChart";
import CustomBarChart from "../../charts/CustomBarChart";
import BoxGrey from "../../BoxGrey";
import useFixedIncome from "./hook";

/**
 * Desktop version of the fixed income component of the portfolio analysis page.
 */
const FixedIncome = () => {
  const [amountOrPercentage, setAmountOrPercentage] = useState("amount");
  const { boxText, loading, dataCreditRisk, dataByGeography, dataByCurrency, dataByCreditRating, dataByProductType } = useFixedIncome();

  return (
    <BoxSection title="Fixed Income" text={boxText} id="portfolio-analysis-fixed-income">
      {loading && <Spinner animation="border" />}
      {!loading && (
        <div>
          <div className="row">
            <div className="col">
              <p className="section-lead">
                A label lorem lorem lorem lorem lorem,
                <br />
                Click the lorem lorem lorem lorem.
              </p>
            </div>
            <div className="col">
              <AmountOrPercentageToggle
                handleChange={(value) => {
                  setAmountOrPercentage(value);
                }}
                amountOrPercentage={amountOrPercentage}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-sm-12 col-md-4">
              <BoxGrey title="Alocação por risco de crédito">
                <CustomPieChart amountOrPercentage={amountOrPercentage} items={dataCreditRisk.Items} nameKey="CreditRisk" innerRadius={30} outerRadius={60} />
              </BoxGrey>
            </div>
            <div className="col-sm-12 col-md-4">
              <BoxGrey title="Alocação por geografia (%)">
                <CustomBarChart items={dataByGeography.Items} amountOrPercentage={amountOrPercentage} xKey="CountryName" />
              </BoxGrey>
            </div>
            <div className="col-sm-12 col-md-4">
              <BoxGrey title="Alocação por rating de crédito" subtitle={<p>*Apenas investimentos directos</p>}>
                <CustomPieChart amountOrPercentage={amountOrPercentage} items={dataByCreditRating.Items} nameKey="CreditRating" innerRadius={30} outerRadius={60} />
              </BoxGrey>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <BoxGrey title="Structure by type of product">
                <CustomPieChart amountOrPercentage={amountOrPercentage} items={dataByProductType.Items} nameKey="ProductType" innerRadius={30} outerRadius={60} layout="vertical" />
              </BoxGrey>
            </div>
            <div className="col-sm-12 col-md-6">
              <BoxGrey
                title="Structure by currency"
                subtitle={
                  <p>
                    Click a currency to see risk metrics
                    <br />
                    - ony for direct investments,
                    <br />
                    excuding funds and ETFs
                  </p>
                }
              >
                <CustomPieChart amountOrPercentage={amountOrPercentage} items={dataByCurrency.Items} nameKey="CurrencySymbol" innerRadius={0} outerRadius={60} layout="vertical" />
              </BoxGrey>
            </div>
          </div>
        </div>
      )}
    </BoxSection>
  );
};

export default FixedIncome;
