import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import MobileCard from "../../MobileCard";
import MobileSection from "../../MobileSection";
import useAssetAllocationEvolution from "./hook";
import CustomAreaChart from "../../charts/CustomAreaChart";
import MobilePagination from "../../MobilePagination";
import MobileDatePicker from "../../MobileDatePicker";

/**
 * Mobile version of the asset allocation evolution component of the portfolio overview page.
 */
const MobileAssetAllocationEvolution = () => {
  const { loading, dataPortfolioByMonth, setStartDate, setEndDate } =
    useAssetAllocationEvolution();

  const [showDateModal, setShowDateModal] = useState(false);

  if (loading) return <Spinner animation="border" />;

  return (
    <div style={{ marginTop: "1rem" }}>
      <MobileSection
        title="Asset allocation evolution"
        subtitle="A label lorem lorem lorem lorem, Click the lorem lorem lorem"
      >
        <MobileCard>
          <CustomAreaChart items={dataPortfolioByMonth} height={300} />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginLeft: "-0.5rem",
              marginRight: "-0.5rem",
              marginBottom: "1rem",
              backgroundColor: "rgba(100, 155, 255, 0.1)",
              padding: "0.5rem",
            }}
          >
            <button
              className="btn btn-outline-primary"
              style={{ width: "80%" }}
              onClick={() => setShowDateModal(true)}
            >
              Filters
            </button>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="btn btn-outline-primary"
              style={{ width: "80%" }}
            >
              In and out rows
            </button>
          </div>
        </MobileCard>
      </MobileSection>

      <MobilePagination
        leftLink="/portfolio/performance"
        leftText="Performance"
        rightLink="/portfolio/agenda"
        rightText="Agenda"
      />

      <MobileDatePicker
        showDateModal={showDateModal}
        setShowDateModal={setShowDateModal}
        onDateSelect={(startDate, endDate) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
      />
    </div>
  );
};

export default MobileAssetAllocationEvolution;
