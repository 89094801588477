import React from "react";
import { useAuth } from "oidc-react";
import { Redirect } from "react-router-dom";

/**
 * Represents the auth page
 */
const Auth = () => {
  const auth = useAuth();

  // if (auth && !auth.userData) {
  //   auth.signIn();
  // }

  return <Redirect to="/dashboard" />;
};

export default Auth;
