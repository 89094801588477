import React, { useEffect, useState } from "react";
import BoxSection from "../BoxSection";
import { useAccount } from "../../AccountContext";
import { formatMoney } from "../../utils/format";
import { colors } from "../../utils/colors";
import { chartOptionDoughnut } from "../../utils/charts";
import _ from "lodash";
import { Doughnut, Bar } from "react-chartjs-2";
import { portfolioGetTotalAssetClass } from "../../api";
import { Spinner } from "react-bootstrap";

/**
 * Represents the portfolio analysis performance component.
 */
const Performance = () => {
  const currency = "EUR";
  const accountId = useAccount();
  const [boxText, setBoxText] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const totalAssetClassData = portfolioGetTotalAssetClass(accountId, currency, "1");

      const formattedVal = formatMoney(parseFloat(totalAssetClassData));
      setBoxText(`TOTAL FIXED INCOME: ${formattedVal} ${currency}`);

      setLoading(false);
    };

    fetchData();
  }, [accountId, currency]);

  return (
    <BoxSection title="Performance" text={boxText} id="portfolio-analysis-performance">
      {loading && <Spinner animation="border" />}
    </BoxSection>
  );
};

export default Performance;
