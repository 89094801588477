import React, { useEffect, useState } from "react";
import { portfolioListCashFlowLines } from "../../../api";
import { useAccount } from "../../../AccountContext";

/**
 * Has the logic of the cash flow projection component.
 */
const useCashFlowProjection = () => {
  const accountId = useAccount();
  const currency = "EUR";
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await portfolioListCashFlowLines(accountId, currency);

      console.log("ListCashFlowLines");
      console.log(data.Items);
      setData(data.Items);

      setLoading(false);
    };

    fetchData();
  }, [accountId]);

  return { accountId, currency, data, loading };
};

export default useCashFlowProjection;
