/**
 * Represents the search component of the sidebar.
 */
const Search = () => {
  const doSearch = (e) => {
    e.preventDefault();
    console.log("search");
  };

  return (
    <section className="sidebar-search bg-grey">
      <form method="post" onSubmit={doSearch}>
        <div className="d-flex input-group justify-content-center align-items-center">
          <input className="form-control" type="text" placeholder="Search" />

          <button className="btn" type="submit">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                fill="#898D8D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.4393 18.4393C19.0251 17.8536 19.9749 17.8536 20.5607 18.4393L29.5607 27.4393C30.1464 28.0251 30.1464 28.9749 29.5607 29.5607C28.9749 30.1464 28.0251 30.1464 27.4393 29.5607L18.4393 20.5607C17.8536 19.9749 17.8536 19.0251 18.4393 18.4393Z"
                fill="#898D8D"
              />
            </svg>
          </button>
        </div>
      </form>
    </section>
  );
};

export default Search;
