import React, { useEffect, useState } from "react";

/**
 * Represents the personal banker component of the sidebar.
 */
const PersonalBanker = ({ name, avatar, status = "offline" }) => {
  const [st, setSt] = useState("offline");

  function changeStatus(e) {
    e.preventDefault();
    setSt(st === "online" ? "offline" : "online");
  }

  useEffect(() => {
    setSt(status);
  }, [status]);

  return (
    <div>
      <div className="personal-banker">
        <div>
          <div className="avatar">
            <img src={avatar} alt={name} />
          </div>
        </div>
        <div>
          <h4 className="name">
            <strong>Banker</strong>
            <br />
            {name}
          </h4>
          <div
            className={st === "online" ? "status-online" : "status-offline"}
            onClick={changeStatus}
          >
            is {st}
          </div>
          <ul className="actions">
            <li>
              <a href="?">Chat</a>
            </li>
            <li>
              <a href="?">Call</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PersonalBanker;
