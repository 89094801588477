import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import React, { useState } from "react";
import { AuthProvider } from "oidc-react";

import { AccountProvider } from "./AccountContext";

import Auth from "./screens/Auth";
import Login from "./screens/Login";
import AppHeader from "./components/AppHeader";
import AppHeaderLogin from "./components/AppHeaderLogin";
import AppFooter from "./components/AppFooter";
import AppFooterLogin from "./components/AppFooterLogin";
import Dashboard from "./screens/Dashboard";
import PortfolioAnalysis from "./screens/PortfolioAnalysis";
import PortfolioOverview from "./screens/PortfolioOverview";
import PersonalBanker from "./components/PersonalBanker";
import MobileToolbox from "./components/MobileToolbox";
import MobileSlideMenu from "./components/MobileSlideMenu";

import { oidcConfig } from "./config/oidc";

import "./App.css";
import MSafe from "./screens/MSafe";

/**
 * Represents all major routes ofn the app.
 */
const Routes = () => (
  <Switch>
    <Route path="/" exact component={Login}></Route>
    <Route path="/dashboard" component={Dashboard}></Route>
    <Route path="/portfolio/:block?" component={PortfolioOverview}></Route>
    <Route
      path="/portfolio-analysis/:block?"
      component={PortfolioAnalysis}
    ></Route>
    <Route path="/msafe" component={MSafe} />
    <Route path="/auth" component={Auth}></Route>
    {false && (
      <Route
        path="/redirect"
        render={() => {
          return <Redirect to="/dashboard" />;
        }}
      ></Route>
    )}
  </Switch>
);

/**
 * Represents the whole app.
 */
function App() {
  const path = useLocation().pathname;
  const [menuActive, setMenuActive] = useState(false);

  const onOpenMenu = (e) => {
    e.preventDefault();
    setMenuActive(true);

    setTimeout(() => {
      document
        .querySelector(".mobile-menu")
        .classList.add("mobile-menu-active");
    }, 200);
  };

  const closeMenu = (e) => {
    e && e.preventDefault();
    document
      .querySelector(".mobile-menu")
      .classList.remove("mobile-menu-active");
    setTimeout(() => {
      setMenuActive(false);
    }, 400);
  };

  return (
    <AccountProvider>
      <AuthProvider {...oidcConfig}>
        <div className="min-vh-100 d-flex flex-column">
          {path === "/" && <AppHeaderLogin></AppHeaderLogin>}
          {path !== "/" && <AppHeader></AppHeader>}

          <Routes />

          {path === "/" && <AppFooterLogin></AppFooterLogin>}
          {false && path !== "/" && <AppFooter></AppFooter>}

          {path !== "/" && menuActive && (
            <div className="mobile-menu d-flex d-md-none flex-column">
              <div className="flex-grow-1 d-flex position-relative">
                <MobileSlideMenu onCloseMenu={closeMenu}></MobileSlideMenu>
              </div>

              <div className="menu-mid">
                <a className="flex-grow-1" href="?">
                  Trade
                </a>
                <a className="flex-grow-1" href="?">
                  Transfer
                </a>
              </div>
              <div className="menu-bottom">
                <PersonalBanker
                  name="Justin Merville"
                  avatar="/images/justin-merville.png"
                  status="online"
                />

                <div>
                  <a className="close" href="?" onClick={closeMenu}>
                    <svg
                      width="49"
                      height="50"
                      viewBox="0 0 49 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="3.53568"
                        y1="3.99512"
                        x2="45.6463"
                        y2="46.1057"
                        stroke="white"
                        strokeWidth="5.9994"
                        strokeLinecap="round"
                      />
                      <line
                        x1="45.2889"
                        y1="4.23831"
                        x2="3.17833"
                        y2="46.3489"
                        stroke="white"
                        strokeWidth="5.9994"
                        strokeLinecap="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          )}

          {path !== "/" && (
            <MobileToolbox onOpenMenu={onOpenMenu}></MobileToolbox>
          )}
        </div>
      </AuthProvider>
    </AccountProvider>
  );
}

export default App;
