import { Redirect } from "react-router-dom";

/**
 * Represents the dashboard page.
 */
const Dashboard = () => {
  return <Redirect to="/portfolio" />;
};

export default Dashboard;
