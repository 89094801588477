import React from "react";
import { Spinner } from "react-bootstrap";
import MobileCard from "../../MobileCard";
import MobileSection from "../../MobileSection";
import usePortfolioCheck from "./hook";
import { roundPercentage } from "../../../utils/format";
import CustomBarChart from "../../charts/CustomBarChart";
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  Bar,
  LabelList,
} from "recharts";
import {
  BsSquareFill,
  BsCircleFill,
  BsDiamondFill,
  BsCaretDownFill,
} from "react-icons/bs";
import HorizontalBarGraph from "./HorizontalRiskGraph";
import Help from "../../../assets/images/help.png";
import { FiAlertCircle, FiCheckCircle, FiXCircle } from "react-icons/fi";
import "./mobileStyles.scss";
import VerticalSingleBarGraph from "./VerticalSingleBarGraph";

/**
 * Represents a mobile porfolio check component of the portfolio analysis page.
 */
const MobilePortfolioCheck = () => {
  const {
    boxText,
    loading,
    currentExposure,
    currentExposureChart,
    allocation,
    risk,
    sectorExposureTable,
    issuerExposureTable,
  } = usePortfolioCheck();

  if (loading)
    return (
      <MobileSection
        title="Portfolio Check"
        subtitle="label explaining what we are seeing"
      >
        <Spinner animation="border" />
      </MobileSection>
    );

  return (
    <div>
      <MobileSection
        title="Portfolio Check"
        subtitle="label explaining what we are seeing"
        secondSubtitle={boxText}
      >
        <MobileCard title="Exposição Cambial (%)">
          <CustomBarChart
            amountOrPercentage="percentage"
            items={currentExposureChart}
            xKey="currency"
          />

          <div className="box-legend">
            <p>
              <b>{currentExposure.ReferenceCurrency} </b>Exposição mínima
              recomendada na sua moeda de referência:
              {` ${roundPercentage(currentExposure.Recommended)}%`}
            </p>
          </div>
        </MobileCard>

        <MobileCard title="Alocação de ativos (%)">
          <ResponsiveContainer width="100%" height={200}>
            <ComposedChart layout="vertical" data={allocation}>
              <Bar stackId="a" dataKey="Minimum" fill="#C5C5C5"></Bar>
              <Bar
                stackId="a"
                dataKey="Maximum"
                fill="#649BFF"
                background={{ fill: "#C5C5C5" }}
              >
                <LabelList
                  dataKey="Minimum"
                  position="insideLeft"
                  color="white"
                  style={{ fill: "white" }}
                  formatter={(value) => `${roundPercentage(value)}%`}
                />
                <LabelList
                  dataKey="Maximum"
                  position="right"
                  style={{ fill: "white" }}
                  formatter={(value) => `${roundPercentage(value)}%`}
                />
              </Bar>
              <Scatter dataKey="Current" shape="diamond" fill="#FF9A89" />
              <Scatter dataKey="Recommended" fill="black" />

              <XAxis type="number" hide />
              <YAxis
                dataKey="AssetClassName"
                type="category"
                width={120}
                fontSize="small"
                interval={0}
              />
              <Tooltip formatter={(value) => `${roundPercentage(value)}%`} />
            </ComposedChart>
          </ResponsiveContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "14px" }}>
              <BsDiamondFill style={{ fill: "#FF9A89", margin: "0.5rem" }} />A
              estrutura do seu portfólio atual
            </div>
            <div style={{ fontSize: "14px" }}>
              <BsSquareFill style={{ fill: "#649BFF", margin: "0.5rem" }} />
              Alocação para o perfil de risco
            </div>
            <div style={{ fontSize: "14px" }}>
              <BsCircleFill style={{ fill: "black", margin: "0.5rem" }} />
              Alocação tática recomendada
            </div>
          </div>
        </MobileCard>

        <MobileCard title="Indicador de risco do portfolio">
          <HorizontalBarGraph
            minLow={0}
            maxLow={5}
            minMedium={0}
            maxMedium={5}
            minHigh={0}
            maxHigh={5}
            minGrey={2}
            maxGrey={9}
            current={5}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "14px" }}>
              <BsCaretDownFill
                style={{ fill: "#FF9A89", marginRight: "0.5rem" }}
              />
              Risco associado ao seu portfólio atual
            </div>
            <div style={{ fontSize: "14px" }}>
              <BsSquareFill
                style={{
                  fill: "rgba(196, 196, 196, 0.4)",
                  marginRight: "0.5rem",
                }}
              />
              Intervalo de risco recomendado, dado seu perfil
            </div>
          </div>

          <div style={{ backgroundColor: "#F9F9F9", marginTop: "1rem" }}>
            <div className="row">
              <div className="col">
                <div
                  style={{
                    display: "flex",
                    padding: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <h4 style={{ fontSize: "16px", color: "#91989E" }}>
                    Perda Estimada (%)
                  </h4>

                  <img
                    src={Help}
                    alt="Help"
                    style={{ height: "25px", marginLeft: "0.5rem" }}
                  />
                </div>
              </div>

              <div className="col">
                <VerticalSingleBarGraph
                  max={risk.ExpectedExpectedShortfallMaximum}
                  min={risk.ExpectedExpectedShortfallMinimum}
                  current={risk.CurrentExpectedShortfall}
                />
              </div>
            </div>
          </div>

          <div style={{ backgroundColor: "#F9F9F9", marginTop: "1rem" }}>
            <div className="row">
              <div className="col">
                <div
                  style={{
                    display: "flex",
                    padding: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <h4 style={{ fontSize: "16px", color: "#91989E" }}>
                    Valor em Risco (%)
                  </h4>

                  <img
                    src={Help}
                    alt="Help"
                    style={{ height: "25px", marginLeft: "0.5rem" }}
                  />
                </div>
              </div>

              <div className="col">
                <VerticalSingleBarGraph
                  max={risk.ExpectedValueAtRiskMaximum}
                  min={risk.ExpectedValueAtRiskMinimum}
                  current={risk.CurrentValueAtRisk}
                />
              </div>
            </div>
          </div>

          <div style={{ backgroundColor: "#F9F9F9", marginTop: "1rem" }}>
            <div className="row">
              <div className="col">
                <div
                  style={{
                    display: "flex",
                    padding: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <h4 style={{ fontSize: "16px", color: "#91989E" }}>
                    Volatilidade (%)
                  </h4>

                  <img
                    src={Help}
                    alt="Help"
                    style={{ height: "25px", marginLeft: "0.5rem" }}
                  />
                </div>
              </div>

              <div className="col">
                <VerticalSingleBarGraph
                  max={risk.ExpectedVolatilityMaximum}
                  min={risk.ExpectedVolatilityMinimum}
                  current={risk.CurrentVolatility}
                />
              </div>
            </div>
          </div>

          <div className="box-white">
            <h4 className="box-white-header">Exposição por setor</h4>

            <table className="table table-sm table-mbp">
              <tbody>
                {sectorExposureTable.map((value, index) => (
                  <tr key={index} className={index % 2 ? "even" : "odd"}>
                    <td>{value.index}</td>
                    <td>{`${roundPercentage(value.value)}%`}</td>
                    <td>
                      {value.value < 0.4 ? (
                        <FiCheckCircle className="box-white-icon box-white-icon-check" />
                      ) : value.value <= 5 ? (
                        <FiAlertCircle className="box-white-icon box-white-icon-alert" />
                      ) : (
                        <FiXCircle className="box-white-icon box-white-icon-warning" />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="box-white-text box-white-legend">
              <div className="p-1">
                Includes direct investiment and funds / ETFs
              </div>
            </div>
            <div
              className="box-white-legend"
              style={{ justifyContent: "space-between" }}
            >
              <div className="box-white-text box-white-legend-element p-1">
                <FiCheckCircle className="box-white-icon box-white-icon-check mr-1" />
                <h3>Menos de 40%</h3>
              </div>
              <div className="box-white-text box-white-legend-element p-1">
                <FiAlertCircle className="box-white-icon box-white-icon-alert mr-1" />
                <h3>Entre 40% e 50%</h3>
              </div>
              <div className="box-white-text box-white-legend-element p-1">
                <FiXCircle className="box-white-icon box-white-icon-warning mr-1" />
                <h3>Mais de 50%</h3>
              </div>
            </div>
          </div>

          <div className="box-white">
            <h4 className="box-white-header">Exposição por emitentes</h4>

            <table className="table table-sm table-mbp">
              <tbody>
                {issuerExposureTable.map((value, index) => (
                  <tr key={index} className={index % 2 ? "even" : "odd"}>
                    <td>{value.index}</td>
                    <td>{`${roundPercentage(value.value)}%`}</td>
                    <td>
                      {value.value < 0.16 ? (
                        <FiCheckCircle className="box-white-icon box-white-icon-check" />
                      ) : value.value <= 0.2 ? (
                        <FiAlertCircle className="box-white-icon box-white-icon-alert" />
                      ) : (
                        <FiXCircle className="box-white-icon box-white-icon-warning" />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="box-white-text box-white-legend">
              <div className="p-1">Includes direct investments</div>
            </div>
            <div
              className="box-white-legend"
              style={{ justifyContent: "space-between" }}
            >
              <div className="box-white-text box-white-legend-element p-1">
                <FiCheckCircle className="box-white-icon box-white-icon-check mr-1" />
                <h3>Menos de 10%</h3>
              </div>
              <div className="box-white-text box-white-legend-element p-1">
                <FiAlertCircle className="box-white-icon box-white-icon-alert mr-1" />
                <h3>Entre 16% e 20%</h3>
              </div>
              <div className="box-white-text box-white-legend-element p-1">
                <FiXCircle className="box-white-icon box-white-icon-warning mr-1" />
                <h3>Mais de 20%</h3>
              </div>
            </div>
          </div>
        </MobileCard>

        <MobileCard title="Outros riscos potenciais"></MobileCard>
      </MobileSection>
    </div>
  );
};

export default MobilePortfolioCheck;
