import React from "react";
import { Spinner } from "react-bootstrap";
import AccountCard from "./AccountCard";
import useAccounts from "./hook";
import "./styles.scss";

/**
 * Desktop version of the accounts component.
 */
const Accounts = () => {
  const { loading, data } = useAccounts();

  return (
    <div className="accounts">
      <h2 className="title">Accounts & Services</h2>
      <hr className="hr" />
      <h3 className="subtitle">Choose account below for portfolio overview</h3>

      {loading && <Spinner animation="border" />}
      {!loading && !data.length && <p>Nothing to show</p>}

      {!loading && data.length && (
        <div className="accounts-container">
          {data.map((row) => (
            <AccountCard key={row.Id} account={row}></AccountCard>
          ))}
        </div>
      )}
    </div>
  );
};

export default Accounts;
