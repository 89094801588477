import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

/**
 * Represents a grey box component.
 */
const BoxGrey = ({ title, children, subtitle }) => (
  <div className="box-grey">
    <h3 className="box-grey-title">{title}</h3>
    {subtitle && <div className="box-grey-subtitle">{subtitle}</div>}
    {children}
  </div>
);

BoxGrey.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  subtitle: PropTypes.node,
};

export default BoxGrey;
