import React, { createRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Stage, Layer, Rect, Line, Text } from "react-konva";

/**
 * Represents a horizontal risk graph.
 */
const HorizontalBarGraph = ({
  minLow,
  maxLow,
  minMedium,
  maxMedium,
  minHigh,
  maxHigh,
  minGrey,
  maxGrey,
  current,
}) => {
  const [graphWidth, setGraphWidth] = useState(0);
  const divRef = createRef();

  useEffect(() => {
    if (divRef.current) {
      const { current } = divRef;
      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;

      setGraphWidth(width);
    }
  }, [divRef]);

  // calculate percentages
  const total = maxLow - minLow + maxMedium - minMedium + maxHigh - minHigh;

  const lowPercentage = (maxLow - minLow) / total;
  const mediumPercentage = (maxMedium - minMedium) / total;
  const highPercentage = (maxHigh - minHigh) / total;
  const greyPercentage = (maxGrey - minGrey) / total;
  const currentPercentage = current / total;

  // positions
  const Ypos = 25;
  const cubeHeight = 100;

  const lowCubeStartPositionX = 0;
  const lowCubeWidth = graphWidth * lowPercentage;

  const moderateCubeStartPositionX = lowCubeStartPositionX + lowCubeWidth;
  const moderateCubeWidth = graphWidth * mediumPercentage;

  const highCubeStartPositionX = moderateCubeStartPositionX + moderateCubeWidth;
  const highCubeWidth = graphWidth * highPercentage;

  const greyCubeStartPositionX = (minGrey / total) * graphWidth;
  const greyCubeWidth = graphWidth * greyPercentage;

  const currentStartPositionX = currentPercentage * graphWidth;

  return (
    <div style={{ width: "100%" }} ref={divRef}>
      <Stage width={graphWidth} height={200}>
        <Layer>
          {/* gray cube  */}
          <Rect
            x={greyCubeStartPositionX}
            y={Ypos - 10}
            width={greyCubeWidth}
            height={cubeHeight + 20}
            fill="#EBEBEA"
          />
          {/* low cube  */}
          <Rect
            x={lowCubeStartPositionX}
            y={Ypos}
            width={lowCubeWidth}
            height={cubeHeight}
            fill="rgba(100, 155, 255, 0.2)"
          />
          <Text
            x={0 + lowCubeWidth / 2 - 20}
            y={Ypos + cubeHeight / 2 - 10}
            text="Baixo"
            fill="black"
            fontStyle="bold"
            fontSize={15}
          />
          {/* moderate cube  */}
          <Rect
            x={moderateCubeStartPositionX}
            y={Ypos}
            width={moderateCubeWidth}
            height={cubeHeight}
            fill="rgba(100, 155, 255, 0.6)"
          />
          <Text
            x={moderateCubeStartPositionX + moderateCubeWidth / 2 - 30}
            y={Ypos + cubeHeight / 2 - 10}
            text="Moderado"
            fill="white"
            fontStyle="bold"
            fontSize={15}
          />
          {/* high cube  */}
          <Rect
            x={highCubeStartPositionX}
            y={Ypos}
            width={highCubeWidth}
            height={cubeHeight}
            fill="#649BFF"
          />
          <Text
            x={highCubeStartPositionX + highCubeWidth / 2 - 20}
            y={Ypos + cubeHeight / 2 - 10}
            text="Alto"
            fill="white"
            fontStyle="bold"
            fontSize={15}
          />
          {/* indicator */}
          <Line
            x={currentStartPositionX - 7.5}
            y={0}
            points={[0, 0, 15, 0, 7.5, 12]}
            tension={0}
            closed
            fill="#FF9A89"
          />
        </Layer>
      </Stage>
    </div>
  );
};

export default HorizontalBarGraph;
