export function config(data, labels, colors) {
  return {
    type: "doughnut",
    data: {
      labels,
      datasets: [
        {
          label: "Services",
          data,
          backgroundColor: colors,
          hoverOffset: 4,
        },
      ],
    },
    options: {
      plugins: {
        labels: {
          render: "percentage",
          fontColor: "#000000",
          fontStyle: "bold",
          precision: 1,
          position: "outside",
        },
      },
      tooltips: {
        enabled: true,
        custom: function (tooltip) {
          if (!tooltip) return;
          tooltip.displayColors = true;
        },
        callbacks: {
          label: function (tooltipItem, data) {
            let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            value = Math.round(value * 100) / 100;
            return " " + value;
          },
        },
      },
      showDatasetLabels: false,
      legend: {
        display: true,
        position: "left",
        labels: {
          boxWidth: 12,
        },
      },
    },
  };
}
