import BsPagination from "react-bootstrap/Pagination";
import PageItem from "react-bootstrap/PageItem";

/**
 * Represents the pagination component.
 */
const Pagination = ({ currentPage, postsPerPage, items, onChangePage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(items.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <BsPagination>
        {pageNumbers.map((page) => (
          <PageItem
            key={page}
            active={page === currentPage}
            onClick={() => {
              onChangePage(page);
            }}
          >
            {page}
          </PageItem>
        ))}
      </BsPagination>
    </div>
  );
};

export default Pagination;
