import React, { useState } from "react";
import MobileSection from "../../MobileSection";
import useEquities from "./hook";
import { Spinner } from "react-bootstrap";
import MobileCard from "../../MobileCard";
import CustomBarChart from "../../charts/CustomBarChart";
import MobileCardWithAmountOrPercentageToggle from "../../MobileCardWithAmountOrPercentageToggle";
import CustomPieChart from "../../charts/CustomPieChart";
import MobilePagination from "../../MobilePagination";

/**
 * Mobile version of the equitiees component of the portfolio analysis page.
 */
const MobileEquities = () => {
  const {
    boxText,
    loading,
    dataByGeography,
    dataPortfolioLines,
    dataByCurrency,
    dataByProductType,
  } = useEquities();
  const [
    dataByCurrencyAmountOrPercentage,
    setDataByCurrencyAmountOrPercentage,
  ] = useState("amount");
  const [
    structureByProductTypeAmountOrPercentage,
    setStructureByProductAmountOrPercentage,
  ] = useState("amount");

  if (loading)
    return (
      <MobileSection
        title="Equities"
        subtitle="label explaining what we are seeing"
      >
        <Spinner animation="border" />
      </MobileSection>
    );

  return (
    <div>
      <MobileSection
        title="Equities"
        subtitle="label explaining what we are seeing"
        secondSubtitle={boxText}
      >
        <MobileCard title="Alocação por geografia (%)">
          <CustomBarChart
            items={dataByGeography.Items}
            amountOrPercentage="percentage"
            xKey="CountryName"
          />
        </MobileCard>

        <MobileCardWithAmountOrPercentageToggle
          title="Exposição cambial"
          amountOrPercentage={dataByCurrencyAmountOrPercentage}
          setAmountOrPercentage={setDataByCurrencyAmountOrPercentage}
        >
          <CustomPieChart
            amountOrPercentage={dataByCurrencyAmountOrPercentage}
            items={dataByCurrency.Items}
            nameKey="CurrencySymbol"
            layout="vertical"
          />
        </MobileCardWithAmountOrPercentageToggle>

        <MobileCard title="5 maiories posições">
          <CustomBarChart
            items={dataPortfolioLines.Items}
            amountOrPercentage="percentage"
            xKey="Name"
          />
        </MobileCard>

        <MobileCardWithAmountOrPercentageToggle
          title="Structure by type of product"
          amountOrPercentage={structureByProductTypeAmountOrPercentage}
          setAmountOrPercentage={setStructureByProductAmountOrPercentage}
        >
          <CustomBarChart
            items={dataByProductType.Items}
            amountOrPercentage={structureByProductTypeAmountOrPercentage}
            xKey="ProductType"
          />
        </MobileCardWithAmountOrPercentageToggle>
      </MobileSection>

      <MobilePagination
        leftText="Fixed Income"
        leftLink="/portfolio-analysis/fixed-income"
        rightText="Alternative Investments"
        rightLink="/portfolio-analysis/alternative-investments"
      />
    </div>
  );
};

export default MobileEquities;
