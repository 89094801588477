import React, { Component } from "react";

/**
 * Represents a language switcher.
 */
class LangSwitch extends Component {
  state = {
    langs: ["PT", "EN", "CN", "FR"],
    currentLang: "EN",
    isOpen: false,
  };

  componentDidMount() {
    if (this.props.current) {
      this.setState({ currentLang: this.props.current });
    }

    const qs = new URLSearchParams(window.location.search);
    const lang = qs.get("lang");

    if (lang) {
      this.setState({ currentLang: lang });
    }
  }

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div
        className={`${
          this.state.isOpen
            ? "lang-switch-container is-open"
            : "lang-switch-container"
        }`}
      >
        <span className="btn-mobile" onClick={this.toggleMenu}></span>
        <ul className="lang-switch">
          {this.state.langs.map((lang, i) => {
            return (
              <li
                key={i}
                className={`${
                  this.state.currentLang === lang ? "mx-1 active" : "mx-1"
                }`}
              >
                <a href={`/?lang=${lang}`}>{lang}</a>
              </li>
            );
          })}
          <li className="chevron-down">
            <svg
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M6.99984 9.00059C6.74888 9.00059 6.49791 8.89262 6.30674 8.67703L0.287219 1.88816C-0.0957397 1.45627 -0.0957397 0.756038 0.287219 0.324504C0.669868 -0.107029 1.29078 -0.107029 1.67374 0.324504L6.99984 6.33138L12.3263 0.324504C12.7092 -0.107029 13.3298 -0.107029 13.7125 0.324504C14.0954 0.756038 14.0954 1.45627 13.7125 1.88816L7.69295 8.67703C7.50147 8.89262 7.25081 9.00059 6.99984 9.00059Z"
                  fill="#898D8D"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="14" height="9" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </li>
        </ul>
      </div>
    );
  }
}

export default LangSwitch;
