import React from "react";
import { formatDate, formatMoney } from "../../../utils/format";
import BoxSection from "../../BoxSection";
import Pagination from "../../../components/Pagination";
import Debug from "../../Debug";

import { Dropdown, Spinner } from "react-bootstrap";
import useAgenda from "./hook";

/**
 * Desktop version of the agenda component of the portfolio overview page.
 */
const Agenda = () => {
  const {
    loading,
    postsPerPage,
    data,
    currency,
    setCurrency,
    currentRows,
    currentPage,
    accountId,
    handleChangePage,
  } = useAgenda();

  return (
    <BoxSection title="Agenda" id="portfolio-overview-agenda">
      {loading && <Spinner animation="border" />}
      {!loading && (
        <div>
          <div style={{ marginBottom: "1rem" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ fontWeight: "bold", color: "#91989E" }}>Filter</div>
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "white",
                    color: "#C4C4C4",
                    borderColor: "#C4C4C4",
                    borderRadius: "20px",
                    marginLeft: "1rem",
                  }}
                >
                  Operation
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as="button">Coupon</Dropdown.Item>
                  <Dropdown.Item as="button">Maturity</Dropdown.Item>
                  <Dropdown.Item as="button">All</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div
                style={{
                  fontWeight: "bold",
                  color: "#91989E",
                  marginLeft: "1rem",
                }}
              >
                Filter
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "white",
                    color: "#C4C4C4",
                    borderColor: "#C4C4C4",
                    borderRadius: "20px",
                    marginLeft: "1rem",
                  }}
                >
                  {currency === "all" ? "Currency" : currency}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      setCurrency("USD");
                    }}
                  >
                    USD
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      setCurrency("EUR");
                    }}
                  >
                    EUR
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      setCurrency("all");
                    }}
                  >
                    All
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {!currentRows.length && (
            <p className="empty-result">Nothing to show</p>
          )}

          {!!currentRows.length && (
            <>
              <table className="table table-sm table-mbp">
                <thead>
                  <tr>
                    <th>Security</th>
                    <th className="text-right">Operation</th>
                    <th className="text-center">Due Date</th>
                    <th className="text-center">Currency</th>
                    <th className="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRows.map((row, index) => (
                    <tr key={index} className={index % 2 ? "even" : "odd"}>
                      <td>{row.ProductName}</td>
                      <td className="text-right">
                        {formatMoney(row.Interest)}
                      </td>
                      <td className="text-center">
                        {formatDate(row.NextPaymentDate)}
                      </td>
                      <td className="text-center">{row.CurrencySymbolId}</td>
                      <td className="text-right">{formatMoney(row.Amount)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination
                currentPage={currentPage}
                postsPerPage={postsPerPage}
                items={data}
                onChangePage={handleChangePage}
              />
            </>
          )}
        </div>
      )}

      <Debug apis={[`portfolio/ListPortfolioAgendaLines/${accountId}`]}></Debug>
    </BoxSection>
  );
};

export default Agenda;
