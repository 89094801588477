import React, { useEffect, useState } from "react";
import { portfolioListPortfolioAgendaLines } from "../../../api";
import _ from "lodash";
import { useAccount } from "../../../AccountContext";

/**
 * Has the logic of the agenda component.
 */
const useAgenda = () => {
  const postsPerPage = 20;

  const accountId = useAccount();
  const [data, setData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("all");

  useEffect(() => {
    const fetchData = async () => {
      const data = await portfolioListPortfolioAgendaLines(accountId);

      // filter data
      let filteredData = data.Items;

      if (currency !== "all") {
        filteredData = _.filter(filteredData, { CurrencySymbolId: currency });
      }

      setData(filteredData);

      const indexOfLastPost = currentPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;

      setCurrentRows(filteredData.slice(indexOfFirstPost, indexOfLastPost));

      setLoading(false);
    };

    fetchData();
  }, [accountId, currency, currentPage]);

  const handleChangePage = (page) => {
    console.log(`page:${page}`);
    setCurrentPage(page);

    const indexOfLastPost = page * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    setCurrentRows(data.slice(indexOfFirstPost, indexOfLastPost));
  };

  return {
    loading,
    accountId,
    postsPerPage,
    setCurrentRows,
    data,
    currentRows,
    currentPage,
    setCurrentPage,
    currency,
    setCurrency,
    handleChangePage,
  };
};

export default useAgenda;
