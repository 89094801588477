import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { roundNumber } from "../../utils/format";

const chartColors = [
  "rgba(255, 99, 132, 0.6)",
  "rgba(54, 162, 235, 0.6)",
  "rgba(255, 206, 86, 0.6)",
  "rgba(75, 192, 192, 0.6)",
  "rgba(153, 102, 255, 0.6)",
  "rgba(255, 159, 64, 0.6)",
];

/**
 * Represents a custom area chart.
 */
const CustomAreaChart = ({ items, height = 500 }) => {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart data={items} margin={{ top: 30 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="YearMonth" />
        <YAxis width={100} />
        <Legend />
        <Tooltip
          formatter={(value) => {
            return roundNumber(value).toLocaleString();
          }}
        />
        <Area
          type="monotone"
          dataKey="Cash"
          stackId="1"
          stroke={chartColors[0]}
          fill={chartColors[0]}
        />
        <Area
          type="monotone"
          dataKey="Bonds"
          stackId="1"
          stroke={chartColors[1]}
          fill={chartColors[1]}
        />
        <Area
          type="monotone"
          dataKey="Equities"
          stackId="1"
          stroke={chartColors[2]}
          fill={chartColors[2]}
        />
        <Area
          type="monotone"
          dataKey="Alternatives"
          stackId="1"
          stroke={chartColors[3]}
          fill={chartColors[3]}
        />
        <Area
          type="monotone"
          dataKey="Derivatives"
          stackId="1"
          stroke={chartColors[4]}
          fill={chartColors[4]}
        />
        <Area
          type="monotone"
          dataKey="Other"
          stackId="1"
          stroke={chartColors[5]}
          fill={chartColors[5]}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CustomAreaChart;
