import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

/**
 * Represents the mobile date picker component.
 */
const MobileDatePicker = ({
  showDateModal,
  setShowDateModal,
  onDateSelect,
}) => {
  const [startDate, setStartDate] = useState(new Date(2001, 1, 1));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [inputSelected, setInputSelected] = useState(null);

  return (
    <Modal
      show={showDateModal}
      fullscreen="sm-down"
      onHide={() => setShowDateModal(false)}
    >
      <Modal.Body style={{ backgroundColor: "#FF9A89", color: "white" }}>
        <h3>Filter Date</h3>
        <hr style={{ backgroundColor: "white" }} />
        <div className="row">
          <div className="col">
            <div>From</div>
            <div>
              <button
                className="btn"
                style={{ fontWeight: "bold", color: "white" }}
                onClick={() => setInputSelected("startDate")}
              >
                {startDate.toLocaleDateString()}
              </button>
            </div>
          </div>
          <div className="col">
            <div>To</div>
            <div style={{ fontWeight: "bold" }}>
              <button
                className="btn"
                style={{ fontWeight: "bold", color: "white" }}
                onClick={() => setInputSelected("endDate")}
              >
                {endDate.toLocaleDateString()}
              </button>
            </div>
          </div>
        </div>

        {inputSelected && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DatePicker
              selected={inputSelected === "startDate" ? startDate : endDate}
              onChange={(date) =>
                inputSelected === "startDate"
                  ? setStartDate(date)
                  : setEndDate(date)
              }
              inline
            />
          </div>
        )}

        <button
          className="btn"
          style={{
            width: "100%",
            borderColor: "white",
            color: "white",
            borderRadius: "30px",
            borderWidth: "4px",
          }}
          onClick={() => onDateSelect(startDate, endDate)}
        >
          Apply filter
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default MobileDatePicker;
