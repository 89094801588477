import React, { Component } from "react";
//import ImgPrize from "../assets/images/prize-private-banker.png";
//import { FaLinkedinIn } from "react-icons/fa";

/**
 * Represents the footer of the login page.
 */
class AppFooterLogin extends Component {
  state = {};
  render() {
    return (
      <footer className="bg-gray text-white py-3 flex-none">
        <div className="container-fluid">
          <div className="d-md-flex align-items-center justify-content-between">
            <div className="text-center text-md-left">
              <a className="text-white" href="?">
                Terms & Conditions
              </a>
              |
              <a className="text-white" href="?">
                Privacy Policy
              </a>
              |
              <a className="text-white" href="?">
                Cookie Policy
              </a>
            </div>

            <div className="text-center text-md-right">
              2020 © Millennium Banque Privée
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default AppFooterLogin;
