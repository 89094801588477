import React, { useState, useEffect } from "react";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { msafeListFileCategories } from "../../api";
import { Spinner } from "react-bootstrap";
import Sidebar from "./Sidebar";
import List from "./List";

/**
 * Repreesents the MSafe page.
 */
const MSafe = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const categories = await msafeListFileCategories();

      setCategories(categories);

      setLoading(false);
    };

    getData();

    // const script = document.createElement("script");
    // script.src = "https://mbpv.acp.unblu-env.com/unblu/visitor.js?x-unblu-apikey=FAxvpmCgRIiy1Sv8sk9vSw";
    // script.async = true;
    // document.body.appendChild(script);

    return () => {
      // document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col col-3">
          <Sidebar loading={loading} categories={categories} />
        </div>

        <div className="col col-9">
          <div style={{ paddingTop: "100px" }}>
            <h1 style={{ fontSize: "24px", color: "#8C939A" }}>MSafe</h1>
            <hr style={{ backgroundColor: "#889197", borderWidth: "3px" }} />

            {loading && <Spinner animation="border" />}
            {!loading && (
              <div>
                <h3 style={{ fontSize: "20px", color: "#989EA5" }}>{`Folders(${categories.length})`}</h3>
                {categories.map((category, index) => (
                  <button className="btn btn-outline-primary" key={index} style={index > 0 ? { marginLeft: "0.5rem" } : {}}>
                    <AiOutlineFolderOpen style={{ marginRight: "0.5rem", fontSize: "20px" }} />
                    {category.Name}
                  </button>
                ))}
                <button
                  className="btn btn-outline-primary"
                  style={{
                    marginLeft: "0.5rem",
                    borderColor: "#FDB1A4",
                    color: "#FDB1A4",
                  }}
                >
                  See all documents
                </button>

                <List />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSafe;
