import BoxSection from "../../BoxSection";
import { useAccount } from "../../../AccountContext";

/**
 * Represents the desktop version of the portfolio details component of the portfolio overview page.
 */
const PortfolioDetails = () => {
  const accountId = useAccount();

  return (
    <BoxSection title="Portfolio Details" id="portfolio-portfolio-details">
      <p className="section-lead">A label describing what we are seeing,</p>
      <button className="btn btn-outline-primary">See Portfolio Details</button>
    </BoxSection>
  );
};

export default PortfolioDetails;
