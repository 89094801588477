import React, { useState } from "react";
import BoxSection from "../../BoxSection";
import { Spinner } from "react-bootstrap";
import AmountOrPercentageToggle from "../../AmountOrPercentageToggle";
import CustomPieChart from "../../charts/CustomPieChart";
import BoxGrey from "../../BoxGrey";
import useOtherInvestments from "./hook";

/**
 * Represents the other investments component of the portfolio analysis page (Desktop version).
 */
const OtherInvestments = () => {
  const [amountOrPercentage, setAmountOrPercentage] = useState("amount");
  const { boxText, loading, dataByCurrency } = useOtherInvestments();

  return (
    <BoxSection title="Other Investments" text={boxText} id="portfolio-analysis-other-investments">
      {loading && <Spinner animation="border" />}
      {!loading && (
        <div>
          <div className="row">
            <div className="col">
              <p className="section-lead">label explaining what we are seing</p>
            </div>
            <div className="col">
              <AmountOrPercentageToggle
                handleChange={(value) => {
                  setAmountOrPercentage(value);
                }}
                amountOrPercentage={amountOrPercentage}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-sm-12 col-md-6">
              <BoxGrey title="Exposure by type of product">
                <p className="text-center mt-5">API Method not provided</p>
              </BoxGrey>
            </div>

            <div className="col-sm-12 col-md-6">
              <BoxGrey title="Exposure by currency">
                <CustomPieChart amountOrPercentage={amountOrPercentage} items={dataByCurrency.Items} nameKey="CurrencySymbol" layout="vertical" />
              </BoxGrey>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <BoxGrey title="Exposure by currency"></BoxGrey>
            </div>
          </div>
        </div>
      )}
    </BoxSection>
  );
};

export default OtherInvestments;
