import React from "react";
import useCash from "./hook";
import MobileSection from "../../MobileSection";
import MobileCardWithAmountOrPercentageToggle from "../../MobileCardWithAmountOrPercentageToggle";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import CustomPieChart from "../../charts/CustomPieChart";
import MobileCard from "../../MobileCard";
import MobilePagination from "../../MobilePagination";

/**
 * Mobile version of the cash component of the portfolio analysis page.
 */
const MobileCash = () => {
  const { loading, boxText, dataCurrency } = useCash();
  const [cashAmountOrPercentageToggle, setCashAmountOrPercentageToggle] =
    useState("amount");
  const [
    exposureByCurrencyAmountOrPercentageToggle,
    setExposureByCurrencyAmountOrPercentageToggle,
  ] = useState("amount");

  if (loading)
    return (
      <MobileSection
        title="Cash"
        subtitle="A label explaining what we are seeing"
      >
        <Spinner animation="border" />
      </MobileSection>
    );

  return (
    <div>
      <MobileSection
        title="Cash"
        subtitle="A label explaining what we are seeing"
        secondSubtitle={boxText}
      >
        <MobileCardWithAmountOrPercentageToggle
          title="Exposure by type of instrument"
          amountOrPercentage={cashAmountOrPercentageToggle}
          setAmountOrPercentage={setCashAmountOrPercentageToggle}
        >
          <p className="text-center mt-5">API Method not provided</p>
        </MobileCardWithAmountOrPercentageToggle>
        <MobileCardWithAmountOrPercentageToggle
          title="Exposure by currency"
          amountOrPercentage={exposureByCurrencyAmountOrPercentageToggle}
          setAmountOrPercentage={setExposureByCurrencyAmountOrPercentageToggle}
        >
          <CustomPieChart
            nameKey="CurrencySymbol"
            items={dataCurrency}
            amountOrPercentage={exposureByCurrencyAmountOrPercentageToggle}
            layout="vertical"
          />
        </MobileCardWithAmountOrPercentageToggle>
        <MobileCard>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="btn btn-outline-primary"
              style={{
                width: "80%",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            >
              See More Details
            </button>
          </div>
        </MobileCard>
      </MobileSection>

      <MobilePagination
        rightText="Fixed Income"
        rightLink="/portfolio-analysis/fixed-income"
      />
    </div>
  );
};

export default MobileCash;
