import React from "react";
import { useMediaQuery } from "react-responsive";

/**
 * Checks if the user is using the mobile or desktop version of the website.
 */
const useIsMobile = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return isMobile;
};

export default useIsMobile;
