import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

/**
 * Represents the mobile pagination.
 */
const MobilePagination = ({ leftText, leftLink, rightText, rightLink }) => {
  return (
    <div
      style={{
        marginTop: "1rem",
      }}
    >
      <div className="row">
        <div className="col">
          {leftLink && leftText && (
            <Link to={leftLink}>
              <FiChevronLeft /> {leftText}
            </Link>
          )}
        </div>
        <div
          className="col"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {rightLink && rightText && (
            <Link to={rightLink}>
              {rightText} <FiChevronRight />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

MobilePagination.propTypes = {
  leftText: PropTypes.string,
  leftLink: PropTypes.string,
  rightText: PropTypes.string,
  rightLink: PropTypes.string,
};

export default MobilePagination;
