const oidcConfig = {
  onSignIn: async (user) => {
    window.location.hash = "";
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", user.access_token);
    window.location.replace("/dashboard");
  },
  onSignOut: async (user) => {},
  autoSignIn: false,
  authority: process.env.REACT_APP_AUTH_URL,
  clientId: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URL,
  responseType: "code",
  scope: "openid BCPPortalWebApi offline_access profile",
};

export { oidcConfig };
