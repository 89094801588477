import React, { useState } from "react";
import useServicesAllocation from "./hook";
import CustomPieChart from "../../charts/CustomPieChart";
import { Spinner } from "react-bootstrap";
import MobileSection from "../../MobileSection";
import MobileCardWithAmountOrPercentageToggle from "../../MobileCardWithAmountOrPercentageToggle";
import { Link } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import MobilePagination from "../../MobilePagination";

/**
 * Represents the mobile version of the services allocation component of the portfolio overview page.
 */
const ServicesAllocationMobile = () => {
  const { accountId, loading, dataServices, dataCurrency, currency } =
    useServicesAllocation();
  const [amountOrPercentageServices, setAmountOrPercentageServices] =
    useState("amount");
  const [amountOrPercentageCurrency, setAmountOrPercentageCurrency] =
    useState("amount");
  const [amountOrPercentageAssets, setAmountOrPercentageAssets] =
    useState("amount");

  if (loading) return <Spinner animation="border" />;

  return (
    <div>
      <MobileSection title="Services & Allocation">
        <MobileCardWithAmountOrPercentageToggle
          title="Services"
          amountOrPercentage={amountOrPercentageServices}
          setAmountOrPercentage={setAmountOrPercentageServices}
        >
          <CustomPieChart
            nameKey="ManagementTypeCategoryName"
            items={dataServices.Items}
            amountOrPercentage={amountOrPercentageServices}
          />
        </MobileCardWithAmountOrPercentageToggle>
        <MobileCardWithAmountOrPercentageToggle
          title="Currency"
          amountOrPercentage={amountOrPercentageCurrency}
          setAmountOrPercentage={setAmountOrPercentageCurrency}
        >
          <CustomPieChart
            nameKey="CurrencySymbol"
            items={dataCurrency.Items}
            amountOrPercentage={amountOrPercentageCurrency}
          />
        </MobileCardWithAmountOrPercentageToggle>
        <MobileCardWithAmountOrPercentageToggle
          title="Assets"
          amountOrPercentage={amountOrPercentageAssets}
          setAmountOrPercentage={setAmountOrPercentageAssets}
        />
      </MobileSection>

      <MobilePagination
        leftLink=""
        leftText="Back"
        rightLink="portfolio/portfolio-details"
        rightText="Portfolio Details"
      />
    </div>
  );
};

export default ServicesAllocationMobile;
