import React from "react";
import MobileCard from "./MobileCard";
import AmountOrPercentageToggle from "./AmountOrPercentageToggle";
import PropTypes from "prop-types";

/**
 * Represents the mobile card with a amount or percentage toggle.
 */
const MobileCardWithAmountOrPercentageToggle = ({
  title,
  subtitle,
  children,
  setAmountOrPercentage,
  amountOrPercentage,
}) => {
  return (
    <MobileCard title={title} subtitle={subtitle}>
      {children}

      <div
        style={{
          backgroundColor: "rgba(100, 155, 255, 0.1)",
          display: "flex",
          justifyContent: "center",
          marginLeft: "-0.5rem",
          marginRight: "-0.5rem",
          marginBottom: "-0.5rem",
        }}
      >
        <AmountOrPercentageToggle
          color="#649BFF"
          handleChange={(value) => {
            setAmountOrPercentage(value);
          }}
          amountOrPercentage={amountOrPercentage}
        />
      </div>
    </MobileCard>
  );
};

MobileCardWithAmountOrPercentageToggle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  setAmountOrPercentage: PropTypes.func.isRequired,
  amountOrPercentage: PropTypes.string.isRequired,
};

export default MobileCardWithAmountOrPercentageToggle;
