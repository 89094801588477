import React from "react";
import { AiOutlineMenu, AiOutlineArrowRight } from "react-icons/ai";
import { Dropdown } from "react-bootstrap";
import "./styles.scss";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Represents the mobile navigation component.
 */
const MobileNavigation = ({ links }) => {
  const location = useLocation();

  return (
    <Dropdown>
      <Dropdown.Toggle className="mobile-navigation-toggle">
        <AiOutlineMenu />
      </Dropdown.Toggle>
      <Dropdown.Menu className="mobile-navigation-menu">
        {links &&
          links.map((link) => (
            <Dropdown.Item className="mobile-navigation-item">
              <div className="row">
                <div className="col col-2">
                  {location.pathname === link.url ? (
                    <AiOutlineArrowRight style={{ fontSize: "20px" }} />
                  ) : null}
                </div>
                <div className="col col-10">
                  <Link to={link.url}>{link.name}</Link>
                </div>
              </div>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

MobileNavigation.propTypes = {
  links: PropTypes.array.isRequired,
};

export default MobileNavigation;
