import { useAuth } from "oidc-react";

/**
 * Represents the User Card.
 */
const UserCard = ({ name }) => {
  const auth = useAuth();

  const signOut = (e) => {
    e.preventDefault();
    auth.signOutRedirect();
  };

  return (
    <div className="user-card d-none d-md-flex">
      <div className="d-flex">
        <svg
          width="48"
          height="46"
          viewBox="0 0 48 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M36 12.2386C36 18.2937 30.8879 23.2672 24.5 23.2672C18.1121 23.2672 13 18.2937 13 12.2386C13 6.18353 18.1121 1.20996 24.5 1.20996C30.8879 1.20996 36 6.18353 36 12.2386Z"
            stroke="#91989E"
            strokeWidth="2"
          />
          <path
            d="M47 45.437C47 33.7449 36.7026 24.2666 24 24.2666C11.2975 24.2666 1 33.7449 1 45.437"
            stroke="#91989E"
            strokeWidth="2"
          />
        </svg>
        <div>
          <div className="name">Welcome, {name}.</div>
          <a className="btn-round btn-round-blue" href="?">
            My Account
          </a>
          <a className="btn-round  btn-round-pink" href="/" onClick={signOut}>
            Logout
          </a>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
