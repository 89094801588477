import React, { useState, useEffect } from "react";
import {
  portfolioListPortfolioByAssetClassByMonth,
  portfolioListPortfolioByMonth,
} from "../../../api";
import { useAccount } from "../../../AccountContext";
import _ from "lodash";

/**
 * Has the logic of the asset allocation evolution component.
 */
const useAssetAllocationEvolution = () => {
  const accountId = useAccount();
  const currency = "EUR";

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const [startDate, setStartDate] = useState(new Date(20000, 1, 1));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [loading, setLoading] = useState(true);

  const [dataByMonth, setDataByMonth] = useState(false);
  const [dataPortfolioByMonth, setPortfolioDataByMonth] = useState(false);

  useEffect(() => {
    const dateRange = `${startDate.getFullYear()}${startDate
      .getMonth()
      .toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}${startDate.getDay().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}/${endDate.getFullYear()}${endDate.getMonth().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}${endDate.getDay().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`;

    const fetchDataByMonth = async () => {
      let dataByMonth = await portfolioListPortfolioByMonth(
        accountId,
        currency,
        dateRange
      );

      dataByMonth = dataByMonth.Items;

      const rows = [];
      const listYears = _.chain(dataByMonth).map("Year").uniq().sort().value();

      listYears.forEach((year) => {
        rows[year] = {
          Year: year,
        };
        months.forEach((month) => {
          const cell = _.find(dataByMonth, ["YearMonth", `${year}-${month}`]);
          rows[year][month] = cell ? Math.round(cell.Net * 100) / 100 : "-";
        });
      });

      setDataByMonth(rows);
    };

    const fetchDataPortfolioByMonth = async () => {
      let dataPortfolioByMonth =
        await portfolioListPortfolioByAssetClassByMonth(
          accountId,
          currency,
          dateRange
        );

      dataPortfolioByMonth = _.orderBy(
        dataPortfolioByMonth.Items,
        ["YearMonth"],
        ["asc"]
      );

      setPortfolioDataByMonth(dataPortfolioByMonth);
    };

    const fetchData = async () => {
      await fetchDataByMonth();
      await fetchDataPortfolioByMonth();

      setLoading(false);
    };

    fetchData();
  }, [accountId, currency, startDate, endDate]);

  return {
    accountId,
    currency,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    loading,
    dataByMonth,
    dataPortfolioByMonth,
  };
};

export default useAssetAllocationEvolution;
