import React, { useState } from "react";
import BoxSection from "../../BoxSection";
import { Spinner } from "react-bootstrap";
import AmountOrPercentageToggle from "../../AmountOrPercentageToggle";
import CustomPieChart from "../../charts/CustomPieChart";
import CustomBarChart from "../../charts/CustomBarChart";
import BoxGrey from "../../BoxGrey";
import useAlternativeInvestments from "./hooks";

/**
 * Represents the desktop version of the alternative investments component of the portfolio analysis page.
 */
const AlternativeInvestments = () => {
  const [amountOrPercentage, setAmountOrPercentage] = useState("amount");
  const { boxText, loading, dataPortfolioLines, dataByCurrency, dataByMarketExposure } = useAlternativeInvestments();

  return (
    <BoxSection title="Alternative Investments" text={boxText} id="portfolio-analysis-alternative-investments">
      {loading && <Spinner animation="border" />}
      {!loading && (
        <div>
          <div className="row">
            <div className="col">
              <p className="section-lead">
                A label lorem lorem lorem lorem lorem,
                <br />
                Click the lorem lorem lorem lorem.
              </p>
            </div>
            <div className="col">
              <AmountOrPercentageToggle
                handleChange={(value) => {
                  setAmountOrPercentage(value);
                }}
                amountOrPercentage={amountOrPercentage}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6">
              <BoxGrey title="Alocação por estratégia">
                <CustomPieChart amountOrPercentage={amountOrPercentage} items={dataByMarketExposure.Items} nameKey="MarketExposure" />
              </BoxGrey>
            </div>

            <div className="col-sm-12 col-md-6">
              <BoxGrey
                title="Exposição cambial"
                subtitle={
                  <p>
                    Click a currency to see rdistribution
                    <br />
                    of Funds & EFTs and Direct Investmens
                  </p>
                }
              >
                <CustomPieChart amountOrPercentage={amountOrPercentage} items={dataByCurrency.Items} nameKey="CurrencySymbol" innerRadius={0} outerRadius={60} />
              </BoxGrey>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <BoxGrey title="5 maiores posições">
                <CustomBarChart items={dataPortfolioLines.Items} amountOrPercentage={amountOrPercentage} xKey="Name" />
              </BoxGrey>
            </div>
          </div>
        </div>
      )}
    </BoxSection>
  );
};

export default AlternativeInvestments;
