import React, { useState } from "react";
import { useAccount, useAccountUpdate } from "../../../AccountContext";
import { getRootCard } from "../../../api";
import "./styles.scss";

/**
 * Represents a account card.
 */
const AccountCard = ({ account }) => {
  const accountId = useAccount();
  const accountUpdate = useAccountUpdate();

  const [card, setCard] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleDetails = async () => {
    const card = await getRootCard(account.Id);

    setCard(card);

    setIsOpen(true);
  };

  const handleOpen = () => {
    if (card === false) {
      handleDetails();
      return;
    }

    setIsOpen(!isOpen);
  };

  return (
    <div
      className={
        accountId === account.Id
          ? "account-card shadow active"
          : "account-card shadow"
      }
      onClick={() => {
        accountUpdate(account.Id);
      }}
    >
      <div className="content">
        <div className="d-flex justify-content-between align-items-start">
          <h3 className="title">Account #{account.Id}</h3>
          <span className="details" onClick={handleDetails}>
            + details
          </span>
        </div>

        <h4 className="subtitle">{account.ManagementTypeName}</h4>
      </div>
      {isOpen && (
        <div className="account-details">
          {Object.entries(card).map((item, n) => (
            <dl key={n} className={n % 2 ? "alt" : ""}>
              <dt>{item[0]}</dt>
              <dd>{item[1]}</dd>
            </dl>
          ))}
        </div>
      )}
      <div className="more" onClick={handleOpen}>
        <svg viewBox="0 0 41 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.5 24L0.148401 -1.89314e-08L40.8516 3.53946e-06L20.5 24Z"
            fill="#C4C4C4"
          />
        </svg>
      </div>
    </div>
  );
};

export default AccountCard;
