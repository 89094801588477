import React from "react";
import useAccounts from "./hook";
import { Dropdown, Spinner } from "react-bootstrap";
import { useAccount, useAccountUpdate } from "../../AccountContext";
import { AiOutlineArrowRight } from "react-icons/ai";

/**
 * Mobile version of the accounts component.
 */
const AccountsMobile = () => {
  const { loading, data } = useAccounts();
  const accountUpdate = useAccountUpdate();
  const accountId = useAccount();

  if (loading) return <Spinner animation="border" />;

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          style={{ borderRadius: "30px", marginBottom: "1rem", width: "100%" }}
        >
          Consolidated Position
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ width: "100%", backgroundColor: "#E0EBFF" }}>
          <Dropdown.Header style={{ color: "#649BFF" }}>
            Select account
          </Dropdown.Header>

          {data.map((account, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => accountUpdate(account.Id)}
              style={{ color: "#649BFF" }}
            >
              <div className="row">
                <div className="col col-2">
                  {accountId === account.Id ? (
                    <AiOutlineArrowRight style={{ fontSize: "20px" }} />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col col-10">
                  <div style={{ fontWeight: "bold" }}>{account.Id}</div>
                  <div>{account.ManagementTypeName}</div>
                </div>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AccountsMobile;
