const events = [
  {
    id: 1,
    title: "Event Name 1",
  },
  {
    id: 2,
    title: "Event Name 2",
  },
];

/**
 * Represents the upcoming events component.
 */
const UpcomingEvents = () => {
  return (
    <section className="bg-pink upcoming-events">
      <h3>Upcoming Events</h3>

      {events && events.length && (
        <ul className="events">
          {events.map((event) => (
            <li key={event.id}>
              <a className="event" href={`/events/${event.id}`}>
                {event.title}
              </a>
            </li>
          ))}
        </ul>
      )}

      <a className="event all-events" href="/events">
        View All News & Events
      </a>
    </section>
  );
};

export default UpcomingEvents;
