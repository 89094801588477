const menu = [
  {
    key: "terms-conditions",
    label: "Terms & Conditions",
    url: "/",
    active: true,
  },
  {
    key: "legal-information",
    label: "Legal Information",
    url: "/",
  },
  {
    key: "user-guide",
    label: "User Guide",
    url: "/",
  },
  {
    key: "contacts",
    label: "Contacts",
    url: "/",
  },
];

/**
 * Represents the application footer.
 */
const AppFooter = () => {
  return (
    <footer className="app-footer d-none d-md-block">
      <div className="footer-top d-md-flex justify-content-between">
        <div>
          <ul className="footer-menu">
            {menu.map((item) => (
              <li key={item.key}>
                <a href={item.url} className={item.active ? "active" : ""}>
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="d-flex">
          <div className="social">
            <h4 className="footer-title">Follow us on</h4>
            <div className="text-center">
              <svg
                width="69"
                height="68"
                viewBox="0 0 69 68"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M67.5 34C67.5 51.9287 52.7461 66.5 34.5 66.5C16.2539 66.5 1.5 51.9287 1.5 34C1.5 16.0713 16.2539 1.5 34.5 1.5C52.7461 1.5 67.5 16.0713 67.5 34Z"
                  stroke="#91989E"
                  strokeWidth="3"
                />
                <path
                  d="M25.2239 25.574H18.9182C18.6384 25.574 18.4116 25.8013 18.4116 26.0817V46.3838C18.4116 46.6643 18.6384 46.8916 18.9182 46.8916H25.2239C25.5038 46.8916 25.7305 46.6643 25.7305 46.3838V26.0817C25.7305 25.8013 25.5038 25.574 25.2239 25.574Z"
                  fill="#91989E"
                />
                <path
                  d="M22.0731 15.4822C19.7787 15.4822 17.9121 17.3508 17.9121 19.6477C17.9121 21.9457 19.7787 23.815 22.0731 23.815C24.3656 23.815 26.2307 21.9456 26.2307 19.6477C26.2308 17.3508 24.3656 15.4822 22.0731 15.4822Z"
                  fill="#91989E"
                />
                <path
                  d="M41.2627 25.0696C38.7301 25.0696 36.858 26.1607 35.7224 27.4005V26.0819C35.7224 25.8015 35.4957 25.5742 35.2158 25.5742H29.177C28.8972 25.5742 28.6704 25.8015 28.6704 26.0819V46.384C28.6704 46.6645 28.8972 46.8918 29.177 46.8918H35.4689C35.7488 46.8918 35.9755 46.6645 35.9755 46.384V36.3392C35.9755 32.9543 36.8929 31.6356 39.2473 31.6356C41.8114 31.6356 42.0152 33.7496 42.0152 36.5133V46.3841C42.0152 46.6646 42.2419 46.8919 42.5218 46.8919H48.816C49.0959 46.8919 49.3226 46.6646 49.3226 46.3841V35.2481C49.3226 30.2149 48.365 25.0696 41.2627 25.0696Z"
                  fill="#91989E"
                />
              </svg>
            </div>
          </div>
          <div className="apps">
            <h4 className="footer-title">Download our APP</h4>
            <a href="?">
              <img src="/images/app-store.png" alt="" />
            </a>
            <a href="?">
              <img src="/images/google-play.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bot">
        <div className="d-flex">
          <div className="d-flex align-items-center pr-3 mr-3 border-right-white">
            <span className="mr-2">Safety site by</span>{" "}
            <img src="/images/multicert.png" alt="" />
          </div>
          <div>
            <img src="/images/livro-reclamacoes.png" alt="" />
          </div>
        </div>
        <div>@2020 Millennium Banque Privée. All rights reserved.</div>
      </div>
    </footer>
  );
};

export default AppFooter;
