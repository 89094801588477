import React from "react";
import PropTypes from "prop-types";

/**
 * Represents a mobile card.
 */
const MobileCard = ({ title, subtitle, children }) => {
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        padding: "0.5rem",
        marginTop: "0.5rem",
        border: "0.38px solid #EBEBEA",
        boxShadow: "0px 1.5297867059707642px 5.736700057983398px 0px #00000033",
        width: "100%",
      }}
    >
      {title && (
        <div
          style={{ color: "#91989E", marginLeft: "1rem", fontWeight: "bold" }}
        >
          {title}
        </div>
      )}
      {subtitle && (
        <div style={{ color: "#91989E", marginLeft: "1rem", fontSize: "14px" }}>
          {subtitle}
        </div>
      )}
      {children}
    </div>
  );
};

MobileCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

export default MobileCard;
