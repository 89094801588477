import React from "react";
import BoxSection from "../../BoxSection";
import Debug from "../../Debug";
import { Spinner, Dropdown, DropdownButton } from "react-bootstrap";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomDatePicker from "../../CustomDatePicker";
import { ReactComponent as CumulativeIcon } from "./CumulativeIcon.svg";
import { ReactComponent as MonthIcon } from "./MonthIcon.svg";
import usePerformance from "./hook";

const formatMoney = (value) => {
  if (!value || value === "-") {
    return "-";
  }

  value = Math.round(parseFloat(value) * 100) / 100;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Represents the desktop version of the performance component of the portfolio overview page.
 */
const Performance = () => {
  const {
    loading,
    listMonthlyAndYearlyPerformanceSinceInception,
    calculationType,
    setCalculationType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    performanceOverPeriod,
    annualizedPerformanceOverPeriod,
    performanceSinceInception,
    annualizedPerformanceSinceInception,
    performanceChartData,
    accountId,
    currency,
  } = usePerformance();

  return (
    <BoxSection title="Performance" id="portfolio-performance">
      {loading && <Spinner animation="border" />}
      {!loading && listMonthlyAndYearlyPerformanceSinceInception && (
        <div>
          <div className="row">
            <div className="col">
              <DropdownButton id="dropdown-item-button" title={calculationType}>
                <Dropdown.Item
                  as="button"
                  onClick={() => setCalculationType("TMW")}
                >
                  TMW - Time Weighted
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => setCalculationType("MMW")}
                >
                  MMW
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  margin: "0.5rem",
                  color: "#91989E",
                  fontWeight: "bold",
                }}
              >
                Filter Date
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ color: "#C4C4C4" }}>From</div>
                <CustomDatePicker
                  date={startDate}
                  setDate={(date) => setStartDate(date)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ color: "#C4C4C4" }}>To</div>
                <CustomDatePicker
                  date={endDate}
                  setDate={(date) => setEndDate(date)}
                />
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "1rem" }}>
            <div className="col-sm-12 col-md-3">
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <div className="row">
                  <div className="col col-6" style={{ color: "#649BFF" }}>
                    Performance over period chosen
                  </div>
                  <div
                    className="col col-6"
                    style={{ color: "#91989E" }}
                  >{`${performanceOverPeriod}%`}</div>
                </div>
                <div className="row">
                  <div className="col col-6" style={{ color: "#649BFF" }}>
                    Annualized performance over period chosen
                  </div>
                  <div
                    className="col col-6"
                    style={{ color: "#91989E" }}
                  >{`${annualizedPerformanceOverPeriod}%`}</div>
                </div>
                <div className="row" style={{ marginTop: "1rem" }}>
                  <div className="col col-6" style={{ color: "#91989E" }}>
                    Performance since inception
                  </div>
                  <div
                    className="col col-6"
                    style={{ color: "#91989E" }}
                  >{`${performanceSinceInception}%`}</div>
                </div>
                <div className="row">
                  <div className="col col-6" style={{ color: "#91989E" }}>
                    Annualized performance since inception
                  </div>
                  <div
                    className="col col-6"
                    style={{ color: "#91989E" }}
                  >{`${annualizedPerformanceSinceInception}%`}</div>
                </div>
              </div>

              <button
                className="btn btn-outline-primary"
                style={{ marginBottom: "1rem" }}
              >
                Show most recent performance
              </button>
              <button
                className="btn btn-outline-primary"
                style={{ marginBottom: "1rem" }}
              >
                Show monthly performance
              </button>
            </div>
            <div className="col-sm-12 col-md-9">
              <ResponsiveContainer width="99%" height={400}>
                <ComposedChart
                  width={500}
                  height={400}
                  data={performanceChartData}
                  margin={{ top: 10, bottom: 10 }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="yearMonth" />
                  <YAxis />
                  <Tooltip />
                  <Bar
                    dataKey="monthlyPerformance"
                    barSize={20}
                    fill="#649BFF"
                  />
                  <Line
                    type="monotone"
                    dataKey="cumulativePerformance"
                    stroke="#FF9A89"
                  />
                </ComposedChart>
              </ResponsiveContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: "14px",
                }}
              >
                <div
                  style={{
                    margin: "0.5rem",
                    color: "#91989E",
                    display: "flex",
                  }}
                >
                  <CumulativeIcon
                    style={{ marginRight: "0.2rem", height: "14px" }}
                  />
                  Cumulative
                </div>
                <div
                  style={{
                    margin: "0.5rem",
                    color: "#91989E",
                    display: "flex",
                  }}
                >
                  <MonthIcon
                    style={{ marginRight: "0.2rem", height: "14px" }}
                  />
                  Month
                </div>
              </div>
            </div>
          </div>

          <table className="table table-sm table-mbp">
            <thead>
              <tr>
                <th className="money"></th>
                <th className="money">January</th>
                <th className="money">February</th>
                <th className="money">March</th>
                <th className="money">April</th>
                <th className="money">May</th>
                <th className="money">June</th>
                <th className="money">July</th>
                <th className="money">August</th>
                <th className="money">September</th>
                <th className="money">October</th>
                <th className="money">November</th>
                <th className="money">December</th>
                <th className="money">YTD</th>
              </tr>
            </thead>
            <tbody>
              {listMonthlyAndYearlyPerformanceSinceInception.map(
                (row, index) => (
                  <tr key={index} className={index % 2 ? "even" : "odd"}>
                    <td className="money">{row["Year"]}</td>
                    <td className="money">{formatMoney(row["01"])}</td>
                    <td className="money">{formatMoney(row["02"])}</td>
                    <td className="money">{formatMoney(row["03"])}</td>
                    <td className="money">{formatMoney(row["04"])}</td>
                    <td className="money">{formatMoney(row["05"])}</td>
                    <td className="money">{formatMoney(row["06"])}</td>
                    <td className="money">{formatMoney(row["07"])}</td>
                    <td className="money">{formatMoney(row["08"])}</td>
                    <td className="money">{formatMoney(row["09"])}</td>
                    <td className="money">{formatMoney(row["10"])}</td>
                    <td className="money">{formatMoney(row["11"])}</td>
                    <td className="money">{formatMoney(row["12"])}</td>
                    <td className="money">{formatMoney(row["YTD"])}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}

      <Debug
        apis={[
          `portfolioperformance/ListMonthlyAndYearlyPerformanceSinceInception/${accountId}/${currency}/${calculationType}`,
        ]}
      ></Debug>
    </BoxSection>
  );
};

export default Performance;
