import React, { useEffect, useState } from "react";

/**
 * Represents the notifications component.
 */
const Notifications = ({ total }) => {
  const [count, setCount] = useState(0);

  const incrementCount = (e) => {
    e.preventDefault();
    setCount(count + 1);
  };

  useEffect(() => {
    setCount(++total);
  }, [total]);

  return (
    <div className="notification">
      <a href="?" onClick={incrementCount}>
        <span className="bell">
          <em>{count}</em>
          <svg
            width="46"
            height="47"
            viewBox="0 0 46 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.9995 46.8229C18.5609 46.8229 14.9492 43.3467 14.9492 39.0724C14.9492 38.461 15.4646 37.9648 16.0996 37.9648C16.7346 37.9648 17.2499 38.461 17.2499 39.0724C17.2499 42.124 19.8309 44.6079 22.9995 44.6079C26.1681 44.6079 28.7491 42.123 28.7491 39.0724C28.7491 38.461 29.2644 37.9648 29.8995 37.9648C30.5345 37.9648 31.0498 38.461 31.0498 39.0724C31.0498 43.3457 27.4392 46.8229 22.9995 46.8229Z"
              fill="white"
            />
            <path
              d="M42.5499 40.1805H3.44997C1.54817 40.1805 0 38.69 0 36.859C0 35.8864 0.438792 34.9678 1.20749 34.3347C1.25385 34.2973 1.30452 34.262 1.35519 34.2329C4.88062 31.2851 6.89993 27.0377 6.89993 22.5536V15.8223C6.89993 8.53264 12.2754 2.15323 19.6831 0.656468C20.2944 0.535025 20.9133 0.912848 21.0448 1.51384C21.1763 2.11171 20.7785 2.69817 20.1543 2.8248C13.8063 4.1067 9.19955 9.57373 9.19955 15.8223V22.5536C9.19955 27.7394 6.83956 32.649 2.72332 36.0193C2.68882 36.0484 2.65432 36.0722 2.61766 36.0971C2.41282 36.3027 2.3007 36.5757 2.3007 36.859C2.3007 37.4704 2.81603 37.9665 3.45104 37.9665H42.551C43.186 37.9665 43.7014 37.4704 43.7014 36.859C43.7014 36.5736 43.586 36.3006 43.3844 36.0951C43.3499 36.0733 43.3154 36.0484 43.2831 36.0224C41.1398 34.2838 39.4633 32.1072 38.3011 29.5517C38.0456 28.9912 38.3108 28.3383 38.8919 28.0923C39.4784 27.8504 40.1522 28.1037 40.4077 28.6611C41.4082 30.8554 42.8346 32.7289 44.6512 34.2319C44.7019 34.261 44.7504 34.2942 44.7935 34.3316C45.5622 34.9668 46.001 35.8864 46.001 36.858C45.9999 38.69 44.4517 40.1805 42.5499 40.1805Z"
              fill="white"
            />
          </svg>
        </span>
      </a>
    </div>
  );
};

export default Notifications;
